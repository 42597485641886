<template>
  <!-- Register Dialog -->
  <v-dialog
    v-model="single_time_dialog"
    :width="width"
    content-class="card pa-0 register-dialog "
    v-if="step < 5 && single_time_dialog"
  >
    <v-btn
      icon
      dark
      variant="text"
      class="dialog-close-btn"
      @click="$emit('close-dialog')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <component
      @close-dialog="$emit('close-dialog')"
      :is="currentStepComponent"
      :is_mobile="is_mobile"
      :registeractivity="registeractivity"
      :activity="activity"
      :page="page"
      :stepLabel="stepLabel"
      :locationIndex="locationIndex"
      :changing="true"
      :enroll="enroll"
      :multiple="false"
      :locale="$i18n.locale"
      @next-step="nextStep"
      @prev-step="prevStep"
      @update-data="updateData"
      @register="register"
    />
  </v-dialog>

  <!-- Confirmation Dialog -->
  <relocation-confirm-dialog
    v-else-if="confirm_dialog"
    v-model="confirm_dialog"
    :new_date="registeractivity.date.date / 1000"
    :new_start="registeractivity.date.slot.start"
    :new_end="registeractivity.date.slot.end"
    @close-dialog="$emit('close-dialog'), (confirm_dialog = false)"
    :image="activity.image"
  />
</template>

<script>
import StepOne from "../registercomponents/StepOne.vue";
import StepTwo from "../registercomponents/StepTwo.vue";
import StepThree from "../registercomponents/StepThree.vue";
import StepFour from "../registercomponents/StepFour.vue";
import RelocationConfirmDialog from "../relocation_confirm_dialog.vue";

export default {
  components: {
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    RelocationConfirmDialog,
  },
  props: {
    activity: Object,
    page: Object,
    enroll: Object, // Keeps enroll functionality
  },
  data() {
    return {
      single_time_dialog: true,
      confirm_dialog: false,
      step: 1,
      registeractivity: {
        location: this.enroll.location,
        specialist: this.enroll.activity_specialist,
        enroll_id: this.enroll.enroll_id,
        dates: [],
        date: {
          date: "",
          slot: {},
        },
        reminder: true,
      },
    };
  },
  computed: {
    width() {
      return !window.innerWidth <= 768 ? "640px" : "calc(100% - 32px)";
    },
    stepLabel() {
      const totalSteps = [StepOne, StepTwo, StepThree, StepFour].length;
      return this.$i18n.locale === "nl"
        ? `Stap ${this.step}/${totalSteps}`
        : `Step ${this.step}/${totalSteps}`;
    },
    is_mobile() {
      return window.innerWidth <= 768;
    },
    currentStepComponent() {
      return [StepOne, StepTwo, StepThree, StepFour][this.step - 1];
    },
    locationIndex() {
      return this.activity.locations.findIndex(
        (loc) => loc.id === this.registeractivity.location
      );
    },
  },
  methods: {
    nextStep() {
      this.step += 1;
    },
    prevStep() {
      this.step -= 1;
    },
    updateData(data) {
      console.log(data);
      Object.assign(this.registeractivity, data);
    },
    register() {
      this.registeractivity.title = this.activity.content.nl.banner.title;
      if (!this.sent) {
        this.registeractivity.enroll_id = this.enroll.enroll_id;
        this.sent = true;
        this.registeractivity.date = this.registeractivity.dates[0];
        this.$emit("update-enroll", this.registeractivity);
        this.step = 5;
        this.confirm_dialog = true;
      }
    },
  },
};
</script>
