import { useMainStore } from "@/stores/store";

const functions = {
    checkDate(date, start) {
        const store = useMainStore();
        const enrolls = store.user.enrolls;

        return enrolls.some(enroll =>
            this.isSameDay(enroll.date, date) &&
            this.isTimeInRange(start, enroll.start, enroll.end)
        );
    },

    getEnroll(date, start, language) {
        const store = useMainStore();
        const enrolls = store.user.enrolls;

        const enroll = enrolls.find(enroll =>
            this.isSameDay(enroll.date, date) &&
            this.isTimeInRange(start, enroll.start, enroll.end)
        );

        if (!enroll) return null; // Return null if no matching enroll is found

        // Return the correct banner title based on language
        return language === "nl"
            ? enroll.content?.nl?.banner?.title || null
            : enroll.content?.eng?.banner?.title || null;
    },

    isSameDay(phpEpoch, jsDate) {
        const dt1 = this.convertToAmsterdamDate(phpEpoch * 1000); // Convert PHP epoch (seconds) to JS timestamp (milliseconds)
        const dt2 = this.convertToAmsterdamDate(jsDate.getTime()); // Ensure JS date is also converted

        return dt1.getFullYear() === dt2.getFullYear() &&
            dt1.getMonth() === dt2.getMonth() &&
            dt1.getDate() === dt2.getDate();
    },

    convertToAmsterdamDate(timestamp) {
        const date = new Date(timestamp);

        // Extract the correct year, month, and day using Amsterdam timezone
        const formatter = new Intl.DateTimeFormat('nl-NL', {
            timeZone: 'Europe/Amsterdam',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });

        const formattedDate = formatter.format(date);
        const [day, month, year] = formattedDate.split('-').map(Number);

        return new Date(year, month - 1, day); // Convert back to Date object
    },

    isTimeInRange(start, enrollStart, enrollEnd) {
        // Convert times (formatted as "HH:mm") to comparable numbers (e.g., "10:00" → 1000)
        const timeToNumber = time => {
            const [hours, minutes] = time.split(":").map(Number);
            return hours * 100 + minutes;
        };

        const startTime = timeToNumber(start);
        const enrollStartTime = timeToNumber(enrollStart);
        const enrollEndTime = timeToNumber(enrollEnd);

        return startTime >= enrollStartTime && startTime <= enrollEndTime;
    }
};

export default functions;

