<template>
  <v-dialog
    v-model="relocation_dialog"
    persistent
    width="640"
    content-class="card relocation-confirm-dialog"
  >
    <div class="text-center">
      <h2 v-if="!is_mobile">
        {{
          $i18n.locale == "nl"
            ? `De ${enroll.content.nl.banner.title} is verplaatst`
            : `The ${enroll.content.eng.banner.title} has been moved`
        }}
      </h2>
      <span v-else class="relocation-title">
        {{
          $i18n.locale == "nl"
            ? `De ${enroll.content.nl.banner.title} is verplaatst`
            : `The ${enroll.content.eng.banner.title} has been moved`
        }}
      </span>

      <k-spacing :y="is_mobile ? 4 : 8"></k-spacing>
      <span class="relocation-text" v-html="getTranslatedText"></span>
    </div>

    <div v-if="!is_mobile" class="pa-4 pb-0 mt-4 d-flex justify-center">
      <v-btn
        @click="accept(false)"
        variant="flat"
        class="btn text-white accept-btn"
        color="var(--company-primary)"
      >
        {{ $i18n.locale == "nl" ? "Accepteren" : "Accept" }}
      </v-btn>
    </div>
    <v-row v-else class="mt-4">
      <v-col cols="12">
        <v-btn
          @click="accept(false)"
          variant="flat"
          block
          class="btn text-white accept-btn"
          color="var(--company-primary)"
        >
          {{ $i18n.locale == "nl" ? "Accepteren" : "Accept" }}
        </v-btn>
      </v-col>
    </v-row>
    <k-spacing y="4"></k-spacing>
    <div
      class="d-flex align-center justify-center flex-wrap relocation-text"
      :class="is_mobile ? '' : 'ml-2 '"
    >
      <span v-if="$i18n.locale == 'nl'">
        Kun je niet op deze datum of tijd?
        <span @click="change_dialog = true" class="text-btn">Wijzigen</span> of
        <span @click="delete_dialog = true" class="text-btn">afmelden</span>.
      </span>
      <span v-else>
        Can't make it on this date or time?
        <span @click="change_dialog = true" class="text-btn">Change</span> or
        <span @click="delete_dialog = true" class="text-btn">unsubscribe</span>.
      </span>
    </div>
    <unsubscribeconfirmdialog
      :enroll="enroll"
      v-if="enroll.enroll_id && delete_dialog"
      :title="
        $i18n.locale == 'nl'
          ? enroll.content.nl.banner.title
          : enroll.content.eng.banner.title
      "
      @delete="cancelEnroll"
      v-model="delete_dialog"
      @close-dialog="delete_dialog = false"
    />
    <change-activity-dialog
      @cancel-enroll="cancelEnroll"
      @update-enroll="updateEnroll"
      v-model="change_dialog"
      v-if="enroll.enroll_id && change_dialog"
      :enroll="enroll"
      :page="mainstore.companypage"
      @close-dialog="
        (change_dialog = false), changed ? $emit('close-dialog') : ''
      "
    />
  </v-dialog>
</template>

<script>
import Unsubscribeconfirmdialog from "@/components/unsubscribeconfirmdialog.vue";
import changeActivityDialog from "@/components/dialogs/changeActivityDialog.vue";
import { useMainStore } from "../stores/store";
import api from "../services/api";
import config from "../config";

export default {
  components: {
    Unsubscribeconfirmdialog,
    changeActivityDialog,
  },
  props: {
    title: String,
    enroll: Object,
  },
  data: () => ({
    delete_dialog: false,
    changed: false,
    change_dialog: false,
    confirm_dialog: false,

    mainstore: useMainStore(),
  }),
  watch: {},
  computed: {
    is_mobile() {
      return window.innerWidth <= 768;
    },
    getTranslatedText() {
      if (this.$i18n.locale == "nl") {
        return `Jouw aanmelding op <strong>${new Date(
          this.enroll.confirmation_data["old_date"] * 1000
        ).toLocaleDateString("nl-NL")}</strong> om <strong>${
          this.enroll.confirmation_data["old_start"]
        } - ${
          this.enroll.confirmation_data["old_end"]
        }</strong> is verplaatst naar <strong>${new Date(
          this.enroll.date * 1000
        ).toLocaleDateString("nl-NL")}</strong> om <strong>${
          this.enroll.start
        } - ${this.enroll.end}</strong>.`;
      } else {
        return `Your enroll for  <strong>${new Date(
          this.enroll.confirmation_data["old_date"] * 1000
        ).toLocaleDateString("nl-NL")}</strong> at <strong>${
          this.enroll.confirmation_data["old_start"]
        } - ${
          this.enroll.confirmation_data["old_end"]
        }</strong> has been moved to  <strong>${new Date(
          this.enroll.date * 1000
        ).toLocaleDateString("nl-NL")}</strong> at <strong>${
          this.enroll.start
        } - ${this.enroll.end}</strong>.`;
      }
    },
  },

  methods: {
    accept(canceled) {
      api
        .post(
          `${config.url.api}/v1/activities/${this.enroll.confirmation_reference}/confirmation`,
          {
            status: "accepted",
          }
        )
        .then((res) => {
          if (res.data.success) {
            if (!canceled) {
              this.$emit("close-dialog");

              this.$toast.success(
                this.$i18n.locale == "en"
                  ? "Appointment succesfully confirmed"
                  : "Afspraak bevestigd"
              );
            }
          }
        })
        .catch(() => {});
    },
    updateEnroll(item) {
      api
        .patch(
          `${config.url.api}/v1/activities/${item.enroll_id}/enroll`,
          item.date.slot
        )
        .then((res) => {
          if (res.data.success) {
            this.changed = true;
            this.accept(true);
          }
        })
        .catch(() => {});
    },
    cancelEnroll() {
      api
        .post(
          `${config.url.api}/v1/activities/${this.enroll.enroll_id}/unsubscribe`,
          this.enroll
        )
        .then((res) => {
          if (res.data.s == 12) {
            this.$toast.error(
              this.$t("views.template.enroll_update_cancel_text")
            );
          }
          if (res.data.success) {
            this.$toast.success(
              this.$t("views.template.enroll_update_cancel_succes")
            );
            this.accept(true);
            this.$emit("close-dialog");

            this.delete_dialog = false;
          }
        })
        .catch((err) => {
          switch (err.response.status) {
            case 400:
              this.$toast.error(
                this.$t("views.template.enroll_update_cancel_error")
              );
              break;

            default:
              this.$toast.error(
                this.$t("views.template.enroll_update_cancel_error")
              );
              break;
          }
        });
    },
  },
};
</script>
