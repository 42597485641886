<template>
  <v-navigation-drawer
    app
    location="left"
    v-model="editdrawer"
    width="316"
    height="100%"
    class="k-app-sidebar"
  >
    <div
      class="k-app-sidebar-head"
      @click="$router.push('/')"
      style="cursor: pointer"
    >
      <v-img
        :src="`${configdata.url.cdn}/pages/${mainStore.companypage.logo}`"
        width="170"
        height="40"
      ></v-img>
    </div>
    <k-spacing y="6"></k-spacing>
    <div class="k-app-sidebar-body">
      <v-list nav color="var(--company-primary)">
        <v-list-item-group>
          <v-list-item
            link
            v-for="item in sidebar_items"
            :key="item.title"
            :to="item.to"
            rounded="xl"
            color="var(--company-primary)"
          >
            <template v-slot:prepend>
              <v-icon :icon="item.icon"></v-icon>
            </template>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>

    <div class="k-app-sidebar-footer" v-if="mainStore.user.id != -1">
      <div class="pa-5">
        <a href="https://defittemedewerker.nl" target="_blank">
          <k-app-logo style="cursor: pointer" width="170"></k-app-logo>
        </a>
      </div>
      <v-divider></v-divider>
      <v-list lines="two">
        <v-list-item
          :title="mainStore.user.firstname + ' ' + mainStore.user.lastname"
          :subtitle="mainStore.user.email"
          @click="$router.push('/accountgegevens')"
        >
          <template v-slot:prepend>
            <v-avatar
              style="cursor: pointer"
              @click.stop="$router.push('/accountgegevens')"
              color="var(--company-primary)"
              size="48"
              :image="`${configdata.url.cdn}/users/avatars/${mainStore.user.profilepicture}`"
            ></v-avatar>
          </template>

          <template v-slot:append>
            <v-btn
              @click.stop.prevent="signout()"
              color="#696969"
              icon="mdi-logout"
              variant="text"
            ></v-btn>
          </template>
        </v-list-item>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { useMainStore } from "@/stores/store";
import config from "@/config";
const cookies = require("js-cookie");
import api from "../../../services/api";
export default {
  // setup() {
  // 	const mainStore = useMainStore();

  // 	return {
  // 		name: mainStore.user.firstname + " " + mainStore.user.lastname,
  // 		profilepicture: mainStore.user.profilepicture,
  // 		userMail: mainStore.user.email,
  // 		company_logo: mainStore.companypage.logo,
  // 	};
  // },

  data() {
    const linkQuery = this.$route.query.link; // Access the route query here

    return {
      // Access the route query here

      mainStore: useMainStore(),

      editdrawer: false,

      // Martijn even de functie maken voor de nav items voor ingelogde en uitgelogde users.
      admin_items: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard-outline",
          to: "/",
        },
        {
          title: "Bedrijfspagina",
          icon: "mdi-briefcase-outline",
          to: "/bedrijfspagina",
        },

        {
          title: "Accountgegevens",
          icon: "mdi-account-circle-outline",
          to: "/accountgegevens",
        },
        {
          title: "Contact",
          icon: "mdi-help-circle-outline",
          to: "/contact",
        },
      ],
      eng_admin_items: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard-outline",
          to: "/",
        },
        {
          title: "Companypage",
          icon: "mdi-briefcase-outline",
          to: "/bedrijfspagina",
        },

        {
          title: "Account details",
          icon: "mdi-account-circle-outline",
          to: "/accountgegevens",
        },
        {
          title: "Contact",
          icon: "mdi-help-circle-outline",
          to: "/contact",
        },
      ],

      logged_out_items: [
        {
          title: "Bedrijfspagina",
          icon: "mdi-briefcase-outline",
          to: `/openbare-pagina?link=${linkQuery}`,
        },
      ],

      mini: true,
    };
  },
  computed: {
    configdata() {
      return config;
    },
    sidebar_items() {
      const mainStore = useMainStore();
      if (mainStore.companypage.data != undefined) {
        if (mainStore.user.id == -1) {
          return this.logged_out_items;
        } else {
          if (this.$i18n.locale == "nl") {
            return this.admin_items;
          } else {
            return this.eng_admin_items;
          }
        }
      } else {
        return [];
      }
    },
  },
  watch: {
    drawer() {
      this.editdrawer = this.drawer;
    },
    editdrawer() {
      this.$emit("open-drawer", this.editdrawer);
    },
  },
  props: {
    drawer: Boolean,
  },

  mounted() {
    this.editdrawer = this.drawer;
  },
  methods: {
    updateUserRole(value) {
      this.user_role = value;
    },

    redirect(item, type) {
      if (type == "link") {
        window.open(item, "_blank");
      } else {
        this.$router.push(item);
      }
    },

    signout() {
      api
        .put(`v1/auth/logout`, {
          refresh_token: cookies.get("fmw_refresh_token"),
        })
        .then((res) => {
          if (res.data.success) {
            cookies.remove("fmw_access_token", {
              domain: config.cookiedomain,
              path: "/",
            });
            cookies.remove("fmw_refresh_token", {
              domain: config.cookiedomain,
              path: "/",
            });
            cookies.remove("user", {
              domain: config.cookiedomain,
              path: "/",
            });
            let subdomain = location.hostname.split(".", 1);
            let config_login = config.url.login.replace(
              "{{domain}}",
              subdomain[0]
            );
            location.replace(`${config_login}/inloggen`);
          }
        })
        .catch((err) => {
          switch (err.response.status) {
            default:
              this.$toast.error("Oops, something went wrong");
              break;
          }
        });
    },
  },
};
</script>
<style>
.k-app-sidebar {
  align-content: space-between;
  height: 100%;
  display: flex;
}

.k-app-sidebar-head {
  padding-top: var(--spacing-6);
  padding-left: var(--spacing-6);
  padding-right: var(--spacing-3);
}

.k-app-sidebar-body {
  padding-left: var(--spacing-3);
  padding-right: var(--spacing-3);
}

.k-app-sidebar-body .v-icon {
  opacity: 1 !important;
}

.k-app-sidebar-footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: var(--spacing-0);
}

.v-list-item__prepend .v-list-item__spacer {
  width: 16px !important;
}

.k-app-sidebar-footer .v-list-item-title {
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: 600;
  color: var(--neutral-700, #4e4e4e);
}
.k-app-sidebar-footer .v-list-item-subtitle {
  color: var(--neutral-600, #696969);
  font-family: Open Sans;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.16rem;
}
</style>
