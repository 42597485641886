// StepFour.vue
<template>
  <div class="stepper-view">
    <div class="step-head pa-6 pa-md-8">
      <span class="step-nr">{{ stepLabel }}</span>
      <k-spacing y="8"></k-spacing>
      <p class="step-subtitle" v-if="!changing">
        {{ $t("components.registerActivityDialog.dialog_stepper_text") }}
      </p>
      <p class="step-subtitle" v-else>
        {{ $i18n.locale == "nl" ? "Wijzig aanmelding" : "Change registration" }}
        <span v-if="changing && enroll.type == 'groepsverband'">
          {{ $i18n.locale == "nl" ? "groepsactiviteit" : "group activity" }}
        </span>
      </p>
      <h3 class="step-title" :class="is_mobile ? 'mt-1' : 'mt-1'">
        {{
          locale === "nl"
            ? activity.content.nl.banner.title
            : activity.content.eng.banner.title
        }}
        <v-chip
          v-if="changing && enroll.type == 'eenopeen'"
          class=""
          color="var(--company-primary)"
          variant="tonal"
        >
          {{ $i18n.locale == "nl" ? "1 OP 1" : "1 ON 1" }}
        </v-chip>
      </h3>
      <span class="enroll-date" v-if="changing">
        {{
          new Date(enroll.date * 1000)
            .toLocaleDateString("nl-NL", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
            .replace(/(\w{3})/, "$1.")
        }}
        {{ enroll.start }} -
        {{ enroll.end }}
      </span>
    </div>
    <div class="step-body px-6 px-md-8">
      <label class="input-label" for="choose-specialist">
        {{ $i18n.locale == "nl" ? "Aanmeld gegevens" : "Registration details" }}
      </label>
      <k-spacing y="4"></k-spacing>
      <div v-if="registeractivity.dates.length">
        <div v-for="(dateItem, index) in registeractivity.dates" :key="index">
          <v-row class="lh-fix card-light summary-cards">
            <v-col cols="12" md="3" :class="is_mobile ? '' : 'mb-4'">
              <span class="register-card-summary-text">
                {{
                  $t(
                    "components.registerActivityDialog.dialog_stepper_title_4_l_summary_1"
                  )
                }}
              </span>
            </v-col>
            <v-col cols="12" md="9" :class="is_mobile ? 'mb-6' : 'mb-4'">
              <span class="register-card-summary-answer">
                {{ activity.locations[locationIndex].data.city }}
              </span>
            </v-col>
            <v-col cols="12" md="3" :class="is_mobile ? 'mb-1' : 'mb-4'">
              <span class="register-card-summary-text">
                {{
                  $t(
                    "components.registerActivityDialog.dialog_stepper_title_4_l_summary_3"
                  )
                }}
              </span>
            </v-col>
            <v-col cols="12" md="9" :class="is_mobile ? 'mb-6' : 'mb-4'">
              <span class="register-card-summary-answer">
                {{
                  activity.type === "eenopeen"
                    ? registeractivity.specialist === "geen_voorkeur"
                      ? activity.locations[locationIndex].specialists.find(
                          (specialist) =>
                            specialist.id === dateItem.slot.activity_specialist
                        )?.name || "Onbekend" // Use specialist from slot if "geen_voorkeur"
                      : activity.locations[locationIndex].specialists.find(
                          (specialist) =>
                            specialist.id === registeractivity.specialist
                        )?.name || "Onbekend" // Fallback if specialist not found
                    : dateItem.specialist
                }}
              </span>
            </v-col>
            <v-col cols="12" md="3" :class="is_mobile ? '' : 'mb-4'">
              <span class="register-card-summary-text">
                {{
                  $t(
                    "components.registerActivityDialog.dialog_stepper_title_4_l_summary_4"
                  )
                }}
              </span>
            </v-col>
            <v-col cols="12" md="9" :class="is_mobile ? 'mb-6' : 'mb-4'">
              <span class="register-card-summary-answer">
                {{
                  new Date(dateItem.date)
                    .toLocaleDateString("nl-NL", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })
                    .replace(/(\w{3})/, "$1.")
                }}
              </span>
            </v-col>
            <v-col cols="12" md="3" :class="is_mobile ? '' : ''">
              <span class="register-card-summary-text">
                {{
                  $t(
                    "components.registerActivityDialog.dialog_stepper_title_4_l_summary_5"
                  )
                }}
              </span>
            </v-col>
            <v-col cols="12" md="9" :class="is_mobile ? '' : ''">
              <span class="register-card-summary-answer">
                {{ dateItem.slot.start }} - {{ dateItem.slot.end }}
              </span>
            </v-col>
          </v-row>

          <k-spacing y="4"></k-spacing>
        </div>
      </div>
      <label class="input-label" for="choose-specialist">
        {{ $i18n.locale == "nl" ? "Opmerkingen" : "Remarks" }}
      </label>
      <v-textarea
        class="mt-1"
        v-model="remark"
        variant="solo"
        :rows="is_mobile ? 4 : 3"
        :placeholder="
          $i18n.locale == 'nl'
            ? 'Wil je ons nog iets laten weten? Heb je bijvoorbeeld een blessure, of moeten we ergens anders mee rekening houden? Vertel het hier'
            : 'Is there anything else you would like us to know? For example, do you have an injury, or do we need to take something else into account? Tell us here'
        "
      ></v-textarea>
      <p>
        {{
          $t("components.registerActivityDialog.dialog_stepper_title_4_text")
        }}
      </p>
      <k-spacing y="8"></k-spacing>
      <div v-if="mainstore.companypage.settings.invite_colleagues">
        <div class="colleague-text" for="choose-specialist">
          {{
            $t(
              "components.registerActivityDialog.dialog_stepper_title_4_l_invite"
            )
          }}
        </div>
        <k-spacing y="4"></k-spacing>

        <v-autocomplete
          variant="solo"
          class="mt-1"
          hide-details
          multiple
          :items="page.colleagues"
          return-object
          item-value="id"
          item-title="name"
          v-model="colleagues"
          rounded="large"
        >
        </v-autocomplete>
      </div>

      <v-checkbox
        class="ml-n2 mt-6 mt-md-8"
        hide-details
        :label="
          $t('components.changeActivityDialogDialog.dialog_stepper_checkbox_l')
        "
        color="var(--company-primary)"
        v-model="reminder"
      >
      </v-checkbox>
    </div>
    <div class="step-footer pa-6 pa-md-8 pt-md-10">
      <v-row>
        <v-col cols="12" :class="!is_mobile ? 'd-flex' : ''">
          <v-btn
            v-if="is_mobile"
            block
            class="btn text-white"
            color="var(--company-primary)"
            @click="$emit('register')"
          >
            {{
              $i18n.locale == "nl"
                ? changing
                  ? "Wijzig aanmelding"
                  : "Ik meld mij aan"
                : changing
                ? "Change registration"
                : "I sign up"
            }}
          </v-btn>
          <v-btn
            :block="is_mobile"
            class="btn btn-company-primary"
            :class="is_mobile ? 'mt-4' : ''"
            color="var(--company-primary)"
            variant="outlined"
            @click="$emit('prev-step')"
          >
            {{ $t("components.registerActivityDialog.dialog_prev_btn") }}
          </v-btn>
          <k-spacing x="4"></k-spacing>
          <v-btn
            v-if="!is_mobile"
            class="btn text-white"
            color="var(--company-primary)"
            @click="$emit('register')"
          >
            {{
              $i18n.locale == "nl"
                ? changing
                  ? "Wijzig aanmelding"
                  : "Ik meld mij aan"
                : changing
                ? "Change registration"
                : "I sign up"
            }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { useMainStore } from "@/stores/store";

export default {
  watch: {
    reminder() {
      this.$emit("update-data", { reminder: this.reminder });
    },
    colleagues() {
      this.$emit("update-data", { colleagues: this.colleagues });
    },
    remark: {
      handler() {
        clearTimeout(this.remarkTimeout); // Clear any existing timeout
        this.remarkTimeout = setTimeout(() => {
          this.$emit("update-data", { remark: this.remark });
        }, 500); // Adjust the delay as needed
      },
      immediate: false, // Ensures it does not run on component mount
    },
  },

  data: () => ({
    reminder: true,
    remark: "",
    colleagues: [],
    mainstore: useMainStore(),
  }),
  props: [
    "registeractivity",
    "activity",
    "locationIndex",
    "locale",
    "is_mobile",
    "page",
    "stepLabel",
    "enroll",
    "changing",
  ],
};
</script>
