<template>
  <div class="stepper-view">
    <div class="step-head pa-6 pa-md-8">
      <span class="step-nr">{{ stepLabel }}</span>
      <k-spacing y="8"></k-spacing>

      <p class="step-subtitle" v-if="!changing">
        {{ $t("components.registerActivityDialog.dialog_stepper_text") }}
      </p>
      <p class="step-subtitle" v-else>
        {{ $i18n.locale == "nl" ? "Wijzig aanmelding" : "Change registration" }}
        <span v-if="changing && enroll.type == 'groepsverband'">
          {{ $i18n.locale == "nl" ? "groepsactiviteit" : "group activity" }}
        </span>
      </p>
      <h3 class="step-title" :class="is_mobile ? 'mt-1' : 'mt-1'">
        {{
          locale === "nl"
            ? activity.content.nl.banner.title
            : activity.content.eng.banner.title
        }}
        <v-chip
          v-if="changing && enroll.type == 'eenopeen'"
          class=""
          color="var(--company-primary)"
          variant="tonal"
        >
          {{ $i18n.locale == "nl" ? "1 OP 1" : "1 ON 1" }}
        </v-chip>
      </h3>
      <span class="enroll-date" v-if="changing">
        {{
          new Date(enroll.date * 1000)
            .toLocaleDateString("nl-NL", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
            .replace(/(\w{3})/, "$1.")
        }}
        {{ enroll.start }} -
        {{ enroll.end }}
      </span>
    </div>
    <div class="step-body px-6 px-md-8">
      <label class="input-label" for="choose-settling">{{
        $t("components.changeActivityDialogDialog.dialog_stepper_specialist_l")
      }}</label>
      <k-spacing :y="!is_mobile ? '1' : '1'"></k-spacing>

      <div class="choose-freelancer-card">
        <v-list class="w-100 pa-0 pt-2">
          <v-list-item
            :class="
              registeractivity.specialist == item.id
                ? 'selected-freelancer mb-2 pa-0 ml-2 mr-2 '
                : 'not-selected-freelancer mb-2 pa-0 ml-2 mr-2 '
            "
            @click="
              $emit('update-data', {
                specialist: item.id,
              })
            "
            v-for="(item, index) in filteredSpecialists"
            :key="index"
          >
            <div class="d-flex">
              <v-avatar
                color="var(--company-primary)"
                size="40"
                :image="`${configdata.url.cdn}/specialists/avatars/${item.profilepicture}`"
              ></v-avatar>
              <k-spacing x="4"></k-spacing>
              <span class="register-user-text my-auto">
                {{ truncateContent(item.name) }}
              </span>
            </div>
          </v-list-item>
        </v-list>
      </div>
    </div>
    <div class="step-footer pa-6 pa-md-8 pt-md-10">
      <v-row>
        <v-col cols="12" :class="!is_mobile ? 'd-flex' : ''">
          <v-btn
            v-if="is_mobile"
            :block="is_mobile"
            :disabled="!registeractivity.specialist"
            class="btn text-white quickfix-btn"
            color="var(--company-primary)"
            @click="$emit('next-step')"
          >
            {{ $i18n.locale == "nl" ? "Ga verder" : "Continue" }}
          </v-btn>
          <k-spacing y="4" v-if="is_mobile"></k-spacing>

          <v-btn
            :block="is_mobile"
            class="btn btn-company-primary quickfix-btn"
            color="var(--company-primary)"
            variant="outlined"
            @click="$emit('prev-step')"
          >
            {{ $t("components.changeActivityDialogDialog.dialog_prev_btn") }}
          </v-btn>
          <k-spacing x="4"></k-spacing>
          <v-btn
            v-if="!is_mobile"
            :disabled="!registeractivity.specialist"
            class="btn text-white quickfix-btn"
            color="var(--company-primary)"
            @click="$emit('next-step')"
          >
            {{ $i18n.locale == "nl" ? "Ga verder" : "Continue" }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import config from "@/config";
export default {
  props: [
    "activity",
    "locationIndex",
    "locale",
    "registeractivity",
    "is_mobile",
    "stepLabel",
    "changing",
    "enroll",
  ],
  computed: {
    configdata() {
      return config;
    },
    filteredSpecialists() {
      if (
        !this.activity ||
        !this.activity.locations ||
        typeof this.locationIndex === "undefined" ||
        !this.activity.locations[this.locationIndex]
      ) {
        return []; // Return empty until data is available
      }

      const specialists =
        this.activity.locations[this.locationIndex]?.specialists || [];

      const filteredSpecialists = specialists
        .filter((specialist) =>
          specialist.dates?.some((date) =>
            date.slots?.some((slot) => slot.spots > 0)
          )
        )
        .sort((a, b) => a.name.localeCompare(b.name));

      if (filteredSpecialists.length > 1) {
        filteredSpecialists.unshift({
          id: "geen_voorkeur",
          name: this.$i18n.locale === "nl" ? "Geen voorkeur" : "No preference",
          dates: [],
        });
      }

      if (filteredSpecialists.length === 1) {
        this.$emit("update-data", { specialist: filteredSpecialists[0].id });
      }

      return filteredSpecialists;
    },
  },
  methods: {
    truncateContent(content) {
      const words = content.split(" ");
      if (words.length > 10) {
        const truncated = words.slice(0, 10).join(" ") + "...";
        return truncated;
      } else {
        return content;
      }
    },
  },
};
</script>
