// StepThreeActivities.vue // StepThreeActivities.vue
<template>
  <div class="stepper-view">
    <div class="step-head pa-6 pa-md-8">
      <span class="step-nr">
        {{ stepLabel }}
      </span>

      <k-spacing y="8"></k-spacing>

      <p class="step-subtitle" v-if="!changing">
        {{ $t("components.registerActivityDialog.dialog_stepper_text") }}
      </p>
      <p class="step-subtitle" v-else>
        {{ $i18n.locale == "nl" ? "Wijzig aanmelding" : "Change registration" }}
        <span v-if="changing && enroll.type == 'groepsverband'">
          {{ $i18n.locale == "nl" ? "groepsactiviteit" : "group activity" }}
        </span>
      </p>
      <h3 class="step-title" :class="is_mobile ? 'mt-1' : 'mt-1'">
        {{
          $i18n.locale == "nl"
            ? activity.content.nl.banner.title
            : activity.content.eng.banner.title
        }}
        <v-chip
          v-if="changing && enroll.type == 'eenopeen'"
          class=""
          color="var(--company-primary)"
          variant="tonal"
        >
          {{ $i18n.locale == "nl" ? "1 OP 1" : "1 ON 1" }}
        </v-chip>
      </h3>
      <span class="enroll-date" v-if="changing">
        {{
          new Date(enroll.date * 1000)
            .toLocaleDateString("nl-NL", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
            .replace(/(\w{3})/, "$1.")
        }}
        {{ enroll.start }} -
        {{ enroll.end }}
      </span>
    </div>
    <div class="step-body pl-6 pl-md-8">
      <label
        v-if="!is_mobile"
        class="input-label mb-1 d-block"
        for="choose-date"
      >
        {{ $t("components.registerActivityDialog.dialog_date_choose") }}
      </label>
      <div v-if="!is_mobile">
        <div
          v-for="(item, index) in groupDatesFiltered"
          :key="index"
          class=""
          :class="getClassForDate(item)"
          @click="
            !item.disabled &&
            !checkDateIfNeeded(item.date, item.specialist.slot.start)
              ? selectItem(item)
              : ''
          "
        >
          <div v-if="ready" class="h-100">
            <div class="d-flex h-100">
              <div class="date-col d-flex px-4">
                <div class="align-self-center d-flex flex-column">
                  <span class="group-register-list-month d-block">
                    {{ formatMonth(item.date) }}
                  </span>
                  <span class="group-register-list-date d-block">
                    {{ formatDay(item.date) }}
                  </span>
                  <span class="group-register-list-day d-block">
                    {{ formatWeekday(item.date) }}
                  </span>
                </div>
              </div>
              <div class="d-flex flex-column group-col ga-4 pa-4 w-100">
                <div class="d-flex">
                  <span class="group-register-list-starttime d-block">
                    {{ item.specialist.slot.start }} -
                    {{ item.specialist.slot.end }}
                  </span>
                  <span class="available-spots-text ml-auto">
                    Nog maar
                    {{
                      item.specialist.slot.spots -
                      item.specialist.slot.enrolls.length
                    }}
                    plekken beschikbaar
                  </span>
                </div>

                <div class="d-flex justify-space-between">
                  <div class="d-flex flex-column ga-2">
                    <span class="group-register-list-address text-truncate">
                      {{
                        activity.locations[locationIndex].data.street +
                        ", " +
                        activity.locations[locationIndex].data.postal_code +
                        " " +
                        activity.locations[locationIndex].data.city
                      }}
                    </span>
                    <span class="group-register-list-address text-truncate">
                      {{
                        item.specialist.slot.address.room
                          ? item.specialist.slot.address.room + " | "
                          : ""
                      }}
                      {{ item.specialist.name }}
                    </span>
                  </div>

                  <div class="d-flex">
                    <v-tooltip
                      location="top"
                      v-if="
                        checkDateIfNeeded(item.date, item.specialist.slot.start)
                      "
                    >
                      <template v-slot:activator="{ props }">
                        <div v-bind="props">
                          <v-btn variant="text" icon density="compact">
                            <v-icon size="32">
                              <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g id="info">
                                  <mask
                                    id="mask0_4328_23687"
                                    style="mask-type: alpha"
                                    maskUnits="userSpaceOnUse"
                                    x="0"
                                    y="0"
                                    width="32"
                                    height="32"
                                  >
                                    <rect
                                      id="Bounding box"
                                      width="32"
                                      height="32"
                                      fill="#D9D9D9"
                                    />
                                  </mask>
                                  <g mask="url(#mask0_4328_23687)">
                                    <path
                                      id="info_2"
                                      d="M14.667 22.6667H17.3337V14.6667H14.667V22.6667ZM16.0003 12.0001C16.3781 12.0001 16.6948 11.8723 16.9503 11.6167C17.2059 11.3612 17.3337 11.0445 17.3337 10.6667C17.3337 10.289 17.2059 9.9723 16.9503 9.71675C16.6948 9.46119 16.3781 9.33341 16.0003 9.33341C15.6225 9.33341 15.3059 9.46119 15.0503 9.71675C14.7948 9.9723 14.667 10.289 14.667 10.6667C14.667 11.0445 14.7948 11.3612 15.0503 11.6167C15.3059 11.8723 15.6225 12.0001 16.0003 12.0001ZM16.0003 29.3334C14.1559 29.3334 12.4225 28.9834 10.8003 28.2834C9.1781 27.5834 7.76699 26.6334 6.56699 25.4334C5.36699 24.2334 4.41699 22.8223 3.71699 21.2001C3.01699 19.5779 2.66699 17.8445 2.66699 16.0001C2.66699 14.1556 3.01699 12.4223 3.71699 10.8001C4.41699 9.17786 5.36699 7.76675 6.56699 6.56675C7.76699 5.36675 9.1781 4.41675 10.8003 3.71675C12.4225 3.01675 14.1559 2.66675 16.0003 2.66675C17.8448 2.66675 19.5781 3.01675 21.2003 3.71675C22.8225 4.41675 24.2337 5.36675 25.4337 6.56675C26.6337 7.76675 27.5837 9.17786 28.2837 10.8001C28.9837 12.4223 29.3337 14.1556 29.3337 16.0001C29.3337 17.8445 28.9837 19.5779 28.2837 21.2001C27.5837 22.8223 26.6337 24.2334 25.4337 25.4334C24.2337 26.6334 22.8225 27.5834 21.2003 28.2834C19.5781 28.9834 17.8448 29.3334 16.0003 29.3334Z"
                                      fill="#4E4E4E"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <div class="tooltip-area">
                        <span class="text-normal text-white tooltip-text">
                          {{
                            $i18n.locale == "nl"
                              ? `Je bent al aangemeld voor ${functions.getEnroll(
                                  item.date,
                                  item.specialist.slot.start,
                                  $i18n.locale
                                )} op dit tijdstip`
                              : `You are already signed up for ${functions.getEnroll(
                                  item.date,
                                  item.specialist.slot.start,
                                  $i18n.locale
                                )} at this time`
                          }}
                        </span>
                      </div>
                    </v-tooltip>
                    <v-tooltip location="top" v-else-if="item.disabled">
                      <template v-slot:activator="{ props }">
                        <div v-bind="props">
                          <v-btn variant="text" icon density="compact">
                            <v-icon size="32">
                              <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g id="info">
                                  <mask
                                    id="mask0_4328_23687"
                                    style="mask-type: alpha"
                                    maskUnits="userSpaceOnUse"
                                    x="0"
                                    y="0"
                                    width="32"
                                    height="32"
                                  >
                                    <rect
                                      id="Bounding box"
                                      width="32"
                                      height="32"
                                      fill="#D9D9D9"
                                    />
                                  </mask>
                                  <g mask="url(#mask0_4328_23687)">
                                    <path
                                      id="info_2"
                                      d="M14.667 22.6667H17.3337V14.6667H14.667V22.6667ZM16.0003 12.0001C16.3781 12.0001 16.6948 11.8723 16.9503 11.6167C17.2059 11.3612 17.3337 11.0445 17.3337 10.6667C17.3337 10.289 17.2059 9.9723 16.9503 9.71675C16.6948 9.46119 16.3781 9.33341 16.0003 9.33341C15.6225 9.33341 15.3059 9.46119 15.0503 9.71675C14.7948 9.9723 14.667 10.289 14.667 10.6667C14.667 11.0445 14.7948 11.3612 15.0503 11.6167C15.3059 11.8723 15.6225 12.0001 16.0003 12.0001ZM16.0003 29.3334C14.1559 29.3334 12.4225 28.9834 10.8003 28.2834C9.1781 27.5834 7.76699 26.6334 6.56699 25.4334C5.36699 24.2334 4.41699 22.8223 3.71699 21.2001C3.01699 19.5779 2.66699 17.8445 2.66699 16.0001C2.66699 14.1556 3.01699 12.4223 3.71699 10.8001C4.41699 9.17786 5.36699 7.76675 6.56699 6.56675C7.76699 5.36675 9.1781 4.41675 10.8003 3.71675C12.4225 3.01675 14.1559 2.66675 16.0003 2.66675C17.8448 2.66675 19.5781 3.01675 21.2003 3.71675C22.8225 4.41675 24.2337 5.36675 25.4337 6.56675C26.6337 7.76675 27.5837 9.17786 28.2837 10.8001C28.9837 12.4223 29.3337 14.1556 29.3337 16.0001C29.3337 17.8445 28.9837 19.5779 28.2837 21.2001C27.5837 22.8223 26.6337 24.2334 25.4337 25.4334C24.2337 26.6334 22.8225 27.5834 21.2003 28.2834C19.5781 28.9834 17.8448 29.3334 16.0003 29.3334Z"
                                      fill="#4E4E4E"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <div class="tooltip-area">
                        <span class="text-normal text-white tooltip-text">
                          {{
                            $i18n.locale == "nl"
                              ? "Je hebt al het maximale aantal activiteiten voor deze periode."
                              : "You have already reached the maximum number of activities for this period."
                          }}
                        </span>
                      </div>
                    </v-tooltip>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-column ga-2"></div>
            </div>
          </div>
          <div v-else>
            <v-skeleton-loader height="150" type="article" elevation="0">
            </v-skeleton-loader>
          </div>
        </div>
      </div>

      <v-slide-group
        v-model="slideGroupModel"
        v-else-if="groupDatesFiltered.length > 1 && is_mobile"
        show-arrows
        class="slide-group"
        :class="changing ? 'changing' : ''"
        direction="horizontal"
        ref="slideGroup"
      >
        <v-slide-group-item
          v-for="(item, index) in groupDatesFiltered"
          :key="index"
        >
          <div
            v-if="ready"
            @click="
              !item.disabled &&
              !checkDateIfNeeded(item.date, item.specialist.slot.start)
                ? selectItem(item)
                : ''
            "
            class="group-date-card"
            :class="getClassForDate(item)"
          >
            <div class="group-mobile-row">
              <div class="group-head">
                <span class="group-register-list-day my-auto">
                  {{ formatWeekday(item.date) }}
                </span>
                <span class="group-register-list-date my-auto">
                  {{ formatDay(item.date) }}
                </span>
                <span class="group-register-list-month my-auto">
                  {{ formatMonth(item.date) }}
                </span>
              </div>
              <div class="group-body">
                <span class="group-register-list-starttime mb-4 d-flex">
                  {{ item.specialist.slot.start }} -
                  {{ item.specialist.slot.end }}
                  <v-tooltip
                    v-if="
                      checkDateIfNeeded(item.date, item.specialist.slot.start)
                    "
                  >
                    <template v-slot:activator="{ props }">
                      <div v-bind="props" class="ml-auto mt-n1">
                        <v-icon size="24">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="info">
                              <mask
                                id="mask0_4328_23687"
                                style="mask-type: alpha"
                                maskUnits="userSpaceOnUse"
                                x="0"
                                y="0"
                                width="32"
                                height="32"
                              >
                                <rect
                                  id="Bounding box"
                                  width="32"
                                  height="32"
                                  fill="#D9D9D9"
                                />
                              </mask>
                              <g mask="url(#mask0_4328_23687)">
                                <path
                                  id="info_2"
                                  d="M14.667 22.6667H17.3337V14.6667H14.667V22.6667ZM16.0003 12.0001C16.3781 12.0001 16.6948 11.8723 16.9503 11.6167C17.2059 11.3612 17.3337 11.0445 17.3337 10.6667C17.3337 10.289 17.2059 9.9723 16.9503 9.71675C16.6948 9.46119 16.3781 9.33341 16.0003 9.33341C15.6225 9.33341 15.3059 9.46119 15.0503 9.71675C14.7948 9.9723 14.667 10.289 14.667 10.6667C14.667 11.0445 14.7948 11.3612 15.0503 11.6167C15.3059 11.8723 15.6225 12.0001 16.0003 12.0001ZM16.0003 29.3334C14.1559 29.3334 12.4225 28.9834 10.8003 28.2834C9.1781 27.5834 7.76699 26.6334 6.56699 25.4334C5.36699 24.2334 4.41699 22.8223 3.71699 21.2001C3.01699 19.5779 2.66699 17.8445 2.66699 16.0001C2.66699 14.1556 3.01699 12.4223 3.71699 10.8001C4.41699 9.17786 5.36699 7.76675 6.56699 6.56675C7.76699 5.36675 9.1781 4.41675 10.8003 3.71675C12.4225 3.01675 14.1559 2.66675 16.0003 2.66675C17.8448 2.66675 19.5781 3.01675 21.2003 3.71675C22.8225 4.41675 24.2337 5.36675 25.4337 6.56675C26.6337 7.76675 27.5837 9.17786 28.2837 10.8001C28.9837 12.4223 29.3337 14.1556 29.3337 16.0001C29.3337 17.8445 28.9837 19.5779 28.2837 21.2001C27.5837 22.8223 26.6337 24.2334 25.4337 25.4334C24.2337 26.6334 22.8225 27.5834 21.2003 28.2834C19.5781 28.9834 17.8448 29.3334 16.0003 29.3334Z"
                                  fill="#4E4E4E"
                                />
                              </g>
                            </g>
                          </svg>
                        </v-icon>
                      </div>
                    </template>
                    <div class="tooltip-area">
                      <span class="text-normal text-white tooltip-text">
                        {{
                          $i18n.locale == "nl"
                            ? `Je bent al aangemeld voor ${functions.getEnroll(
                                item.date,
                                item.specialist.slot.start,
                                $i18n.locale
                              )} op dit tijdstip`
                            : `You are already signed up for ${functions.getEnroll(
                                item.date,
                                item.specialist.slot.start,
                                $i18n.locale
                              )} at this time`
                        }}
                      </span>
                    </div>
                  </v-tooltip>
                  <v-tooltip v-else-if="item.disabled">
                    <template v-slot:activator="{ props }">
                      <div v-bind="props" class="ml-auto mt-n1">
                        <v-icon size="24">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="info">
                              <mask
                                id="mask0_4328_23687"
                                style="mask-type: alpha"
                                maskUnits="userSpaceOnUse"
                                x="0"
                                y="0"
                                width="32"
                                height="32"
                              >
                                <rect
                                  id="Bounding box"
                                  width="32"
                                  height="32"
                                  fill="#D9D9D9"
                                />
                              </mask>
                              <g mask="url(#mask0_4328_23687)">
                                <path
                                  id="info_2"
                                  d="M14.667 22.6667H17.3337V14.6667H14.667V22.6667ZM16.0003 12.0001C16.3781 12.0001 16.6948 11.8723 16.9503 11.6167C17.2059 11.3612 17.3337 11.0445 17.3337 10.6667C17.3337 10.289 17.2059 9.9723 16.9503 9.71675C16.6948 9.46119 16.3781 9.33341 16.0003 9.33341C15.6225 9.33341 15.3059 9.46119 15.0503 9.71675C14.7948 9.9723 14.667 10.289 14.667 10.6667C14.667 11.0445 14.7948 11.3612 15.0503 11.6167C15.3059 11.8723 15.6225 12.0001 16.0003 12.0001ZM16.0003 29.3334C14.1559 29.3334 12.4225 28.9834 10.8003 28.2834C9.1781 27.5834 7.76699 26.6334 6.56699 25.4334C5.36699 24.2334 4.41699 22.8223 3.71699 21.2001C3.01699 19.5779 2.66699 17.8445 2.66699 16.0001C2.66699 14.1556 3.01699 12.4223 3.71699 10.8001C4.41699 9.17786 5.36699 7.76675 6.56699 6.56675C7.76699 5.36675 9.1781 4.41675 10.8003 3.71675C12.4225 3.01675 14.1559 2.66675 16.0003 2.66675C17.8448 2.66675 19.5781 3.01675 21.2003 3.71675C22.8225 4.41675 24.2337 5.36675 25.4337 6.56675C26.6337 7.76675 27.5837 9.17786 28.2837 10.8001C28.9837 12.4223 29.3337 14.1556 29.3337 16.0001C29.3337 17.8445 28.9837 19.5779 28.2837 21.2001C27.5837 22.8223 26.6337 24.2334 25.4337 25.4334C24.2337 26.6334 22.8225 27.5834 21.2003 28.2834C19.5781 28.9834 17.8448 29.3334 16.0003 29.3334Z"
                                  fill="#4E4E4E"
                                />
                              </g>
                            </g>
                          </svg>
                        </v-icon>
                      </div>
                    </template>
                    <div class="tooltip-area">
                      <span class="text-normal text-white tooltip-text">
                        {{
                          $i18n.locale == "nl"
                            ? "Je hebt al het maximale aantal activiteiten voor deze periode."
                            : "You have already reached the maximum number of activities for this period."
                        }}
                      </span>
                    </div>
                  </v-tooltip>
                </span>

                <span
                  v-if="item.specialist.slot.location_type == 'location'"
                  class="group-register-list-address d-block mb-4"
                >
                  {{
                    activity.locations[locationIndex].data.street +
                    ", " +
                    activity.locations[locationIndex].data.postal_code +
                    " " +
                    activity.locations[locationIndex].data.city
                  }}
                </span>
                <span v-else class="group-register-list-address d-block mb-4">
                  {{ item.specialist.slot.address.meeting_link }}
                </span>
                <span
                  class="group-register-list-address"
                  v-if="item.specialist.slot.location_type == 'location'"
                >
                  {{ item.specialist.slot.address.room }} |
                  {{ item.specialist.name }}
                </span>
                <!-- <span class="group-register-list-address specialist">
                  {{ item.specialist.name }}
                </span> -->
              </div>
            </div>
          </div>
          <v-skeleton-loader
            v-else
            width="296"
            type="image, article"
            elevation="1"
          >
          </v-skeleton-loader>
        </v-slide-group-item>

        <template v-slot:next v-if="ready">
          <v-btn variant="text" icon>
            <v-icon size="32">mdi-chevron-right</v-icon>
          </v-btn>
        </template>
        <template v-slot:prev v-if="ready">
          <v-btn variant="text" icon>
            <v-icon size="32">mdi-chevron-left</v-icon>
          </v-btn>
        </template>
      </v-slide-group>
      <div
        v-else-if="groupDatesFiltered.length == 1"
        @click="
          !groupDatesFiltered[0].disabled &&
          !checkDateIfNeeded(
            groupDatesFiltered[0].date,
            groupDatesFiltered[0].specialist.slot.start
          )
            ? selectItem(groupDatesFiltered[0])
            : ''
        "
        class="group-date-card single-card"
        :class="getClassForDate(groupDatesFiltered[0])"
      >
        <div class="group-mobile-row">
          <div class="group-head">
            <span class="group-register-list-day my-auto">
              {{ formatWeekday(groupDatesFiltered[0].date) }}
            </span>
            <span class="group-register-list-date my-auto">
              {{ formatDay(groupDatesFiltered[0].date) }}
            </span>
            <span class="group-register-list-month my-auto">
              {{ formatMonth(groupDatesFiltered[0].date) }}
            </span>
          </div>
          <div class="group-body">
            <span class="group-register-list-starttime mb-4 d-flex">
              {{ groupDatesFiltered[0].specialist.slot.start }} -
              {{ groupDatesFiltered[0].specialist.slot.end }}
              <v-tooltip
                v-if="
                  checkDateIfNeeded(
                    groupDatesFiltered[0].date,
                    groupDatesFiltered[0].specialist.slot.start
                  )
                "
              >
                <template v-slot:activator="{ props }">
                  <div v-bind="props" class="ml-auto mt-n1">
                    <v-icon size="24">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="info">
                          <mask
                            id="mask0_4328_23687"
                            style="mask-type: alpha"
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="32"
                            height="32"
                          >
                            <rect
                              id="Bounding box"
                              width="32"
                              height="32"
                              fill="#D9D9D9"
                            />
                          </mask>
                          <g mask="url(#mask0_4328_23687)">
                            <path
                              id="info_2"
                              d="M14.667 22.6667H17.3337V14.6667H14.667V22.6667ZM16.0003 12.0001C16.3781 12.0001 16.6948 11.8723 16.9503 11.6167C17.2059 11.3612 17.3337 11.0445 17.3337 10.6667C17.3337 10.289 17.2059 9.9723 16.9503 9.71675C16.6948 9.46119 16.3781 9.33341 16.0003 9.33341C15.6225 9.33341 15.3059 9.46119 15.0503 9.71675C14.7948 9.9723 14.667 10.289 14.667 10.6667C14.667 11.0445 14.7948 11.3612 15.0503 11.6167C15.3059 11.8723 15.6225 12.0001 16.0003 12.0001ZM16.0003 29.3334C14.1559 29.3334 12.4225 28.9834 10.8003 28.2834C9.1781 27.5834 7.76699 26.6334 6.56699 25.4334C5.36699 24.2334 4.41699 22.8223 3.71699 21.2001C3.01699 19.5779 2.66699 17.8445 2.66699 16.0001C2.66699 14.1556 3.01699 12.4223 3.71699 10.8001C4.41699 9.17786 5.36699 7.76675 6.56699 6.56675C7.76699 5.36675 9.1781 4.41675 10.8003 3.71675C12.4225 3.01675 14.1559 2.66675 16.0003 2.66675C17.8448 2.66675 19.5781 3.01675 21.2003 3.71675C22.8225 4.41675 24.2337 5.36675 25.4337 6.56675C26.6337 7.76675 27.5837 9.17786 28.2837 10.8001C28.9837 12.4223 29.3337 14.1556 29.3337 16.0001C29.3337 17.8445 28.9837 19.5779 28.2837 21.2001C27.5837 22.8223 26.6337 24.2334 25.4337 25.4334C24.2337 26.6334 22.8225 27.5834 21.2003 28.2834C19.5781 28.9834 17.8448 29.3334 16.0003 29.3334Z"
                              fill="#4E4E4E"
                            />
                          </g>
                        </g>
                      </svg>
                    </v-icon>
                  </div>
                </template>
                <div class="tooltip-area">
                  <span class="text-normal text-white tooltip-text">
                    {{
                      $i18n.locale == "nl"
                        ? `Je bent al aangemeld voor ${functions.getEnroll(
                            groupDatesFiltered[0].date,
                            groupDatesFiltered[0].specialist.slot.start,
                            $i18n.locale
                          )} op dit tijdstip`
                        : `You are already signed up for ${functions.getEnroll(
                            groupDatesFiltered[0].date,
                            groupDatesFiltered[0].specialist.slot.start,
                            $i18n.locale
                          )} at this time`
                    }}
                  </span>
                </div>
              </v-tooltip>
              <v-tooltip v-else-if="groupDatesFiltered[0].disabled">
                <template v-slot:activator="{ props }">
                  <div v-bind="props" class="ml-auto mt-n1">
                    <v-icon size="24">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="info">
                          <mask
                            id="mask0_4328_23687"
                            style="mask-type: alpha"
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="32"
                            height="32"
                          >
                            <rect
                              id="Bounding box"
                              width="32"
                              height="32"
                              fill="#D9D9D9"
                            />
                          </mask>
                          <g mask="url(#mask0_4328_23687)">
                            <path
                              id="info_2"
                              d="M14.667 22.6667H17.3337V14.6667H14.667V22.6667ZM16.0003 12.0001C16.3781 12.0001 16.6948 11.8723 16.9503 11.6167C17.2059 11.3612 17.3337 11.0445 17.3337 10.6667C17.3337 10.289 17.2059 9.9723 16.9503 9.71675C16.6948 9.46119 16.3781 9.33341 16.0003 9.33341C15.6225 9.33341 15.3059 9.46119 15.0503 9.71675C14.7948 9.9723 14.667 10.289 14.667 10.6667C14.667 11.0445 14.7948 11.3612 15.0503 11.6167C15.3059 11.8723 15.6225 12.0001 16.0003 12.0001ZM16.0003 29.3334C14.1559 29.3334 12.4225 28.9834 10.8003 28.2834C9.1781 27.5834 7.76699 26.6334 6.56699 25.4334C5.36699 24.2334 4.41699 22.8223 3.71699 21.2001C3.01699 19.5779 2.66699 17.8445 2.66699 16.0001C2.66699 14.1556 3.01699 12.4223 3.71699 10.8001C4.41699 9.17786 5.36699 7.76675 6.56699 6.56675C7.76699 5.36675 9.1781 4.41675 10.8003 3.71675C12.4225 3.01675 14.1559 2.66675 16.0003 2.66675C17.8448 2.66675 19.5781 3.01675 21.2003 3.71675C22.8225 4.41675 24.2337 5.36675 25.4337 6.56675C26.6337 7.76675 27.5837 9.17786 28.2837 10.8001C28.9837 12.4223 29.3337 14.1556 29.3337 16.0001C29.3337 17.8445 28.9837 19.5779 28.2837 21.2001C27.5837 22.8223 26.6337 24.2334 25.4337 25.4334C24.2337 26.6334 22.8225 27.5834 21.2003 28.2834C19.5781 28.9834 17.8448 29.3334 16.0003 29.3334Z"
                              fill="#4E4E4E"
                            />
                          </g>
                        </g>
                      </svg>
                    </v-icon>
                  </div>
                </template>
                <div class="tooltip-area">
                  <span class="text-normal text-white tooltip-text">
                    {{
                      $i18n.locale == "nl"
                        ? "Je hebt al het maximale aantal activiteiten voor deze periode."
                        : "You have already reached the maximum number of activities for this period."
                    }}
                  </span>
                </div>
              </v-tooltip>
            </span>

            <span
              v-if="
                groupDatesFiltered[0].specialist.slot.location_type ==
                'location'
              "
              class="group-register-list-address d-block mb-4"
            >
              {{
                activity.locations[locationIndex].data.street +
                ", " +
                activity.locations[locationIndex].data.postal_code +
                " " +
                activity.locations[locationIndex].data.city
              }}
            </span>
            <span v-else class="group-register-list-address d-block mb-4">
              {{ groupDatesFiltered[0].specialist.slot.address.meeting_link }}
            </span>
            <span
              class="group-register-list-address"
              v-if="
                groupDatesFiltered[0].specialist.slot.location_type ==
                'location'
              "
            >
              {{ groupDatesFiltered[0].specialist.slot.address.room }} |
              {{ groupDatesFiltered[0].specialist.name }}
            </span>
            <!-- <span class="group-register-list-address specialist">
                  {{ item.specialist.name }}
                </span> -->
          </div>
        </div>
      </div>
    </div>
    <div class="step-footer pa-6 pa-md-8 pt-md-10">
      <v-row>
        <v-col cols="12" :class="!is_mobile ? 'd-flex' : ''">
          <v-btn
            v-if="is_mobile"
            :block="is_mobile"
            :disabled="
              changing
                ? !registeractivity.date.slot.id
                : !registeractivity.dates.length > 0
            "
            class="btn text-white quickfix-btn"
            color="var(--company-primary)"
            @click="$emit('next-step')"
          >
            {{ $i18n.locale == "nl" ? "Ga verder" : "Continue" }}
          </v-btn>
          <k-spacing y="4" v-if="is_mobile"></k-spacing>

          <v-btn
            :block="is_mobile"
            class="btn btn-company-primary quickfix-btn"
            color="var(--company-primary)"
            variant="outlined"
            @click="$emit('prev-step')"
          >
            {{ $t("components.changeActivityDialogDialog.dialog_prev_btn") }}
          </v-btn>
          <k-spacing x="4"></k-spacing>
          <v-btn
            v-if="!is_mobile"
            :disabled="
              changing
                ? !registeractivity.date.slot.id
                : !registeractivity.dates.length > 0
            "
            class="btn text-white quickfix-btn"
            color="var(--company-primary)"
            @click="$emit('next-step')"
          >
            {{ $i18n.locale == "nl" ? "Ga verder" : "Continue" }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import api from "../../services/api";
import config from "../../config";
import { useMainStore } from "@/stores/store";
import functions from "@/util/functions";

export default {
  props: [
    "activity",
    "registeractivity",
    "page",
    "stepLabel",
    "locationIndex",
    "is_mobile",
    "enroll",
    "changing",
  ],
  emits: ["prev-step", "next-step", "update-registeractivity"],
  mounted() {
    this.checkCredits();
  },
  data() {
    return {
      slideGroupModel: 0,
      activeDate: null,
      ready: false,
      functions: functions,
      tempSelectedDates: [],
      credits: [],
      first_select: true,
      period: null,
      translateX: 0, // Track the translation
      slideWidth: 250, // Adjust based on your card width
      mainstore: useMainStore(),
      localRegisterActivity: JSON.parse(JSON.stringify(this.registeractivity)), // Make a local copy
    };
  },
  computed: {
    groupDatesFiltered() {
      const dateMap = new Map();
      const location = this.locationIndex;

      // Determine whether to use multiple dates (group) or a single date (changing)
      const selectedDates = this.changing
        ? this.registeractivity.date && this.registeractivity.date.date
          ? [this.registeractivity.date] // Wrap single object in array
          : []
        : this.registeractivity.dates
        ? this.registeractivity.dates.filter((date) => date.date) // Filter out null/undefined dates
        : [];

      // Group existing selected dates by period for credit comparison
      const datesGroupedByPeriod = {};
      selectedDates.forEach((date) => {
        if (!date.date) return; // Skip if date.date is null/undefined

        // Format date to Amsterdam timezone
        const formattedInputDate = new Intl.DateTimeFormat("en-CA", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          timeZone: "Europe/Amsterdam",
        }).format(new Date(date.date));

        const periodStartDate = this.getPeriodStartDate(
          formattedInputDate,
          this.period
        );

        if (!datesGroupedByPeriod[periodStartDate]) {
          datesGroupedByPeriod[periodStartDate] = [];
        }
        datesGroupedByPeriod[periodStartDate].push(date.date);
      });

      // Iterate through each location and its specialists
      this.activity.locations[location].specialists.forEach(
        ({ name, id, dates }) => {
          dates.forEach(({ date, slots }) => {
            if (!date) return; // Skip if date is null/undefined

            // Format date using Amsterdam time
            const formattedDate = new Intl.DateTimeFormat("en-CA", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              timeZone: "Europe/Amsterdam",
            }).format(new Date(date));

            const periodStartDate = this.getPeriodStartDate(
              formattedDate,
              this.period
            );
            const datesInPeriod = datesGroupedByPeriod[periodStartDate] || [];
            const isDisabled =
              datesInPeriod.length >= (this.credits[periodStartDate] || 0);

            slots.forEach((slot) => {
              const alreadySelected = selectedDates.some((activityDate) => {
                return (
                  activityDate.date === date && slot.id === activityDate.slot.id
                );
              });

              // Check if there are entries for this date, if not initialize an array
              if (!dateMap.has(formattedDate)) {
                dateMap.set(formattedDate, []);
              }

              // Append each slot to the date's array
              dateMap.get(formattedDate).push({
                date: date,
                specialist: {
                  id,
                  location,
                  name,
                  slot,
                },
                disabled: isDisabled && !alreadySelected ? isDisabled : false, // Disable if needed
              });
            });
          });
        }
      );

      // Flatten and sort the array by date
      return Array.from(dateMap.values())
        .flat()
        .sort((a, b) => new Date(a.date) - new Date(b.date));
    },
  },
  methods: {
    prevSlide() {
      const container = this.$refs.slideGroup?.$el?.querySelector(
        ".v-slide-group__content"
      );
      if (container) {
        this.translateX = Math.min(this.translateX + this.slideWidth, 0);
        container.style.transform = `translateX(${this.translateX}px)`;
      }
    },
    nextSlide() {
      const container = this.$refs.slideGroup?.$el?.querySelector(
        ".v-slide-group__content"
      );
      if (container) {
        const maxTranslateX = -(container.scrollWidth - container.clientWidth); // Prevent overscrolling
        this.translateX = Math.max(
          this.translateX - this.slideWidth,
          maxTranslateX
        );
        container.style.transform = `translateX(-${this.translateX}px)`;
      }
    },

    checkDateIfNeeded(date, start) {
      if (this.getCookie("fmw_access_token")) {
        return this.functions.checkDate(date, start);
      } else {
        return false;
      }
    },
    getCookie(name) {
      const match = document.cookie.match(
        new RegExp("(^| )" + name + "=([^;]+)")
      );
      return match ? match[2] : false;
    },
    selectItem(item) {
      if (!this.changing) {
        const index = this.registeractivity.dates.findIndex(
          (dateItem) => dateItem.slot.id === item.specialist.slot.id
        );

        let updatedDates = [...this.registeractivity.dates];
        if (index !== -1) {
          updatedDates.splice(index, 1);
        } else {
          updatedDates.push({
            date: item.date,
            slot: item.specialist.slot,
            specialist: item.specialist.name,
          });
        }
        this.$emit("update-data", { dates: updatedDates });
      } else {
        let updatedDate = this.registeractivity.date;

        if (this.registeractivity.date.slot.id == item.specialist.slot.id) {
          updatedDate = {
            date: null,
            slot: {},
            specialist: item.specialist.name,
          };
        } else {
          updatedDate = {
            date: item.date,
            slot: item.specialist.slot,
            specialist: item.specialist.name,
          };
        }
        this.$emit("update-data", { date: updatedDate, dates: [updatedDate] });
      }
    },
    formatMonth(date) {
      return new Date(date).toLocaleDateString("nl-NL", { month: "short" });
    },
    formatDay(date) {
      return new Date(date).toLocaleDateString("nl-NL", { day: "numeric" });
    },
    formatWeekday(date) {
      return (
        new Date(date)
          .toLocaleDateString("nl-NL", { weekday: "long" })
          .charAt(0)
          .toUpperCase() +
        new Date(date).toLocaleDateString("nl-NL", { weekday: "long" }).slice(1)
      );
    },

    getClassForDate(item) {
      let class_thing = "group-date-card";

      const selectedDates = this.changing
        ? this.registeractivity.date && this.registeractivity.date.slot
          ? [this.registeractivity.date] // Wrap single object in array
          : []
        : this.registeractivity.dates
        ? this.registeractivity.dates.filter((dateItem) => dateItem.slot) // Ensure slot exists
        : [];

      const isSelected = selectedDates.some(
        (dateItem) => dateItem.slot.id === item.specialist.slot.id
      );

      if (isSelected) {
        class_thing += " selected-date";
      }

      if (
        (item.disabled && !isSelected) ||
        this.checkDateIfNeeded(item.date, item.specialist.slot.start)
      ) {
        class_thing += " disabled-card";
      }
      // if (this.groupDatesFiltered.length < 2) {
      //   class_thing += " w-100";
      // }
      return class_thing;
    },

    getPeriodStartDate(date, period) {
      const dt = new Date(date);
      if (period === "week") {
        if (dt.getDay() === 0) {
          // If it's Sunday
          dt.setDate(dt.getDate() - 6); // Move back to the previous Monday
        } else {
          // For Monday to Saturday
          dt.setDate(dt.getDate() - (dt.getDay() - 1)); // Adjust to the current week's Monday
        }
      } else if (period === "month") {
        dt.setDate(1); // Set to the first day of the month
      } else if (period === "year") {
        dt.setMonth(0, 1); // Set to the first day of the year
      }
      // Manual formatting to ensure local date:
      return (
        dt.getFullYear() +
        "-" +
        ("0" + (dt.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + dt.getDate()).slice(-2)
      );
    },
    filterDates(dates) {
      // Sort the array based on the 'date' property
      dates.sort((a, b) => {
        // Convert the 'date' strings to Date objects for comparison
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA - dateB;
      });

      // Return the sorted array
      return dates;
    },
    checkCredits() {
      const token = this.getCookie("fmw_access_token");

      if (token) {
        let dateObject = { dates: this.groupDatesFiltered.map((d) => d.date) };
        api
          .post(
            `${config.url.api}/v1/activities/${this.page.id}/checkcredits`,
            dateObject
          )
          .then((res) => {
            this.credits = res.data.dates;
            this.period = res.data.period;
            if (this.changing && this.enroll.date) {
              const formattedEnrollDate = this.formatDateToPeriod(
                new Date(this.enroll.date * 1000),
                this.period
              );

              if (this.credits[formattedEnrollDate]) {
                this.credits[formattedEnrollDate] += 2;
              } else {
                this.credits[formattedEnrollDate] = 2;
              }
            }

            this.ready = true;
          });
      } else {
        // No token -> Use max credits from store settings
        this.credits = this.getMaxCreditsForPeriod();
        this.period = this.mainstore.companypage.settings.period;
        this.ready = true;
      }
    },
    formatDateToPeriod(date, period) {
      // Ensure date is in Amsterdam time
      const dt = new Date(
        date.toLocaleString("en-US", { timeZone: "Europe/Amsterdam" })
      );

      if (period === "week") {
        const dayOfWeek = dt.getDay() || 7; // Convert Sunday (0) to 7
        dt.setDate(dt.getDate() - dayOfWeek + 1); // Move to Monday
      } else if (period === "month") {
        dt.setDate(1); // First day of the month
      } else if (period === "year") {
        dt.setMonth(0, 1); // January 1st
      }

      // Format in YYYY-MM-DD (Europe/Amsterdam timezone)
      return new Intl.DateTimeFormat("en-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        timeZone: "Europe/Amsterdam",
      }).format(dt);
    },

    getMaxCreditsForPeriod() {
      const maxCredits = this.mainstore.companypage.settings.credits;
      const period = this.mainstore.companypage.settings.period; // 'week', 'month', or 'year'
      const today = new Date();
      let periodCredits = {};

      let currentDate = new Date(
        Date.UTC(today.getFullYear(), today.getMonth(), 1) // Ensure start of the month
      );

      // Generate max credits for the next 12 months
      for (let i = 0; i < 12; i++) {
        let startDate;

        switch (period) {
          case "week":
            startDate = new Date(currentDate);
            currentDate.setDate(currentDate.getDate() + 7); // Move forward by a week
            break;

          case "month":
            startDate = new Date(
              currentDate.getUTCFullYear(),
              currentDate.getUTCMonth(),
              1
            ); // First day of the month
            currentDate.setUTCMonth(currentDate.getUTCMonth() + 1);
            break;

          case "year":
            startDate = new Date(currentDate.getUTCFullYear(), 0, 1); // First day of the year
            currentDate.setUTCFullYear(currentDate.getUTCFullYear() + 1);
            break;

          default:
            startDate = new Date(today);
        }

        // Ensure it's in Amsterdam timezone
        let formattedDate = new Intl.DateTimeFormat("en-CA", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          timeZone: "Europe/Amsterdam", // Ensure Amsterdam time is used
        }).format(startDate);

        periodCredits[formattedDate] = maxCredits;
      }

      return periodCredits;
    },
  },
};
</script>
