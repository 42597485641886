<template>
  <div v-if="ready">
    <change-single-time-dialog
      @update-enroll="$emit('update-enroll', $event)"
      :activity="activity"
      :enroll="enroll"
      :page="page"
      v-if="enroll.type == 'eenopeen'"
      v-model="single_time_dialog"
      @close-dialog="single_time_dialog = false"
    />
    <change-group-time-dialog
      @update-enroll="$emit('update-enroll', $event)"
      :activity="activity"
      :enroll="enroll"
      v-else-if="enroll.type == 'groepsverband'"
      :page="page"
      v-model="group_time_dialog"
      @close-dialog="group_time_dialog = false"
    />
  </div>
</template>
<script>
import { useMainStore } from "../../stores/store";
import ChangeSingleTimeDialog from "./changeSingleTimeDialogNew.vue";
import ChangeGroupTimeDialog from "./changeGroupTimeDialogNew.vue";
import api from "../../services/api";
import config from "../../config";
export default {
  components: { ChangeSingleTimeDialog, ChangeGroupTimeDialog },

  props: {
    enroll: Object,
    page: Object,
  },
  watch: {
    group_time_dialog() {
      this.$emit("close-dialog");
    },
    single_time_dialog() {
      this.$emit("close-dialog");
    },
  },
  data: () => ({
    mainStore: useMainStore(),
    single_time_dialog: true,
    group_time_dialog: true,
    selectedDates: [],
    activity: {},
    step: 1,
    ready: false,
    selectedDate: null,
    change_dialog: false,
  }),
  mounted() {
    this.getActivity();
  },

  methods: {
    getActivity() {
      api
        .get(`${config.url.api}/v1/activities/${this.enroll.activity}/client`)
        .then((res) => {
          this.activity = res.data.data;
          this.activity.locations.forEach((location) => {
            location.specialists.forEach((specialist) => {
              specialist.dates = specialist.dates.map((date) => {
                // Convert the ISO string to a Date object first
                const dateObject = new Date(date.date * 1000);

                // Then format it to 'YYYY-MM-DD'
                // This ensures compatibility with the Vuetify date picker

                return {
                  ...date, // Keep the existing properties of the date object
                  date: dateObject, // Replace the date with the formatted one
                };
              });
            });
            // this.registeractivity.location = this.enroll.location;
            // this.registeractivity.specialist = this.enroll.activity_specialist;

            this.ready = true;
          });
        })
        .catch(() => {});
    },
    onDateSelected() {
      if (this.selectedDate) {
        // Add the selected date to the registeractivity.dates array
        this.selectedDates.push(this.selectedDate);
        // Optionally, you can clear the selectedDate if needed
        this.selectedDate = null;
      }
    },

    register() {
      this.registeractivity.id = this.activity.schedule.client_activity;
      this.registeractivity.spots = this.activity.schedule.spots;
      this.registeractivity.date = this.activity.date;
      this.$emit("register-enroll", this.registeractivity);
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    formatDay(epochDate) {
      // Convert epoch date to a Dutch day string (e.g., "donderdag" for Thursday)
      const dateObject = new Date(epochDate * 1000);
      const options = { weekday: "long", timeZone: "UTC" };
      return dateObject.toLocaleDateString("nl-NL", options);
    },
    formatDate(epochDate) {
      // Convert epoch date to a formatted date string (ignoring the time)
      const dateObject = new Date(epochDate);
      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObject.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    customAllowedDates(val) {
      // Check if the selected date is in the array based on the 'data' property
      const selectedDate = this.formatDate(new Date(val));
      if (this.activity.schedule.dates) {
        return (
          this.activity.schedule.dates.some(
            (dateObj) => this.formatDate(dateObj.date * 1000) == selectedDate
          ) && !this.selectedDates.includes(val)
        );
      }
      // else if(this.activity.dates){
      //   return this.activity.dates.some((dateObj) =>
      // this.formatDate(dateObj.date * 1000) == selectedDate);

      // }
      else {
        return true;
      }
    },

    deleteitem() {},
  },
};
</script>
