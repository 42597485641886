<template>
  <div class="stepper-view">
    <div class="step-head pa-6 pa-md-8">
      <span class="step-nr">{{ stepLabel }}</span>

      <k-spacing y="8"></k-spacing>
      <p class="step-subtitle" v-if="!changing">
        {{ $t("components.registerActivityDialog.dialog_stepper_text") }}
      </p>
      <p class="step-subtitle" v-else>
        {{ $i18n.locale == "nl" ? "Wijzig aanmelding" : "Change registration" }}
        <span v-if="changing && enroll.type == 'groepsverband'">
          {{ $i18n.locale == "nl" ? "groepsactiviteit" : "group activity" }}
        </span>
      </p>
      <h3 class="step-title" :class="is_mobile ? 'mt-1' : 'mt-1'">
        {{
          locale === "nl"
            ? activity.content.nl.banner.title
            : activity.content.eng.banner.title
        }}
        <v-chip
          v-if="changing && enroll.type == 'eenopeen'"
          class=""
          color="var(--company-primary)"
          variant="tonal"
        >
          {{ $i18n.locale == "nl" ? "1 OP 1" : "1 ON 1" }}
        </v-chip>
      </h3>
      <span class="enroll-date" v-if="changing">
        {{
          new Date(enroll.date * 1000)
            .toLocaleDateString("nl-NL", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
            .replace(/(\w{3})/, "$1.")
        }}
        {{ enroll.start }} -
        {{ enroll.end }}
      </span>
    </div>
    <div class="step-body px-6 px-md-8">
      <label class="input-label" for="choose-settling">{{
        $t("components.changeActivityDialogDialog.dialog_stepper_branche_l")
      }}</label>
      <k-spacing :y="is_mobile ? 1 : 1"></k-spacing>

      <v-select
        v-model="selectedLocation"
        :items="filterLocations(activity.locations)"
        item-value="id"
        item-title="data.city"
        density="compact"
        hide-details
        variant="solo"
        :placeholder="
          $t('components.registerActivityDialog.dialog_stepper_branche_ph')
        "
        name="choose-settling"
        validate-on-blur
        @update:modelValue="
          $emit('update-data', { location: selectedLocation })
        "
      />
    </div>
    <div class="step-footer pa-6 pa-md-8 pt-md-10">
      <v-row>
        <v-col cols="12" :class="!is_mobile ? 'd-flex' : ''">
          <v-btn
            v-if="is_mobile"
            :block="is_mobile"
            :disabled="!selectedLocation"
            class="btn text-white quickfix-btn"
            color="var(--company-primary)"
            @click="$emit('next-step')"
          >
            {{ locale == "nl" ? "Ga verder" : "Continue" }}
          </v-btn>
          <k-spacing y="4" v-if="is_mobile"></k-spacing>

          <v-btn
            :block="is_mobile"
            class="btn btn-company-primary quickfix-btn"
            color="var(--company-primary)"
            variant="outlined"
            @click="$emit('close-dialog')"
          >
            {{ $t("components.changeActivityDialogDialog.dialog_close_btn") }}
          </v-btn>
          <k-spacing x="4"></k-spacing>
          <v-btn
            v-if="!is_mobile"
            :disabled="!selectedLocation"
            class="btn text-white quickfix-btn"
            color="var(--company-primary)"
            @click="$emit('next-step')"
          >
            {{ locale == "nl" ? "Ga verder" : "Continue" }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    "activity",
    "locale",
    "is_mobile",
    "stepLabel",
    "changing",
    "enroll",
    "registeractivity",
  ],
  data() {
    return { selectedLocation: null };
  },
  mounted() {
    this.selectedLocation = this.registeractivity.location
      ? this.registeractivity.location
      : null;
  },
  methods: {
    filterLocations(locations) {
      const filteredLocations = locations.filter((location) =>
        location.specialists.some((specialist) =>
          specialist.dates.some(
            (date) => date.slots.some((slot) => slot.spots > 0) // Ensure at least one available slot
          )
        )
      );

      // If only one valid location exists, auto-select it and update data
      if (filteredLocations.length === 1) {
        this.selectedLocation = filteredLocations[0].id;
        this.$emit("update-data", { location: this.selectedLocation });
      }

      return filteredLocations;
    },
  },
};
</script>
