<template>
  <div class="d-flex flex-column align-center justify-center w-100 h-100">
    <!-- Image inside the slot of progress-linear -->

    <div
      class="mt-n10"
      :style="{
        width: is_mobile ? `${width / 2}px` : `${width}px`,
        height: is_mobile ? `${height / 2}px` : `${height}px`,
      }"
    >
      <v-img
        v-if="image"
        :src="`${configdata.url.cdn}/pages/${mainStore.companypage.logo}`"
        contain
        class="mb-10"
      ></v-img>
      <v-progress-linear
        :width="is_mobile ? circle_size / 2 : circle_size"
        indeterminate
        color="var(--company-primary) "
      >
      </v-progress-linear>
    </div>
  </div>
</template>

<script>
import { useMainStore } from "@/stores/store";
import config from "@/config";

export default {
  data: () => ({
    mainStore: useMainStore(),
  }),
  props: {
    image: Boolean,
    circle_size: Number,
    width: Number,
    height: Number,
  },
  computed: {
    is_mobile() {
      return window.innerWidth < 768;
    },

    configdata() {
      return config;
    },
  },
};
</script>
