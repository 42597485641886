<template>
  <div class="stepper-view" ref="stepperView">
    <div class="step-head pa-6 pa-md-8">
      <span class="step-nr">{{ stepLabel }}</span>
      <k-spacing y="8"></k-spacing>

      <p class="step-subtitle" v-if="!changing">
        {{ $t("components.registerActivityDialog.dialog_stepper_text") }}
      </p>
      <p class="step-subtitle" v-else>
        {{ $i18n.locale == "nl" ? "Wijzig aanmelding" : "Change registration" }}
      </p>
      <h3 class="step-title" :class="is_mobile ? 'mt-1' : 'mt-1'">
        {{
          locale === "nl"
            ? activity.content.nl.banner.title
            : activity.content.eng.banner.title
        }}
        <v-chip
          v-if="changing && enroll.type == 'eenopeen'"
          class=""
          color="var(--company-primary)"
          variant="tonal"
        >
          {{ $i18n.locale == "nl" ? "1 OP 1" : "1 ON 1" }}
        </v-chip>
      </h3>
      <span class="enroll-date" v-if="changing">
        {{
          new Date(enroll.date * 1000)
            .toLocaleDateString("nl-NL", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
            .replace(/(\w{3})/, "$1.")
        }}
        {{ enroll.start }} -
        {{ enroll.end }}
      </span>
    </div>
    <div class="step-body px-6 px-md-8" v-if="ready">
      <v-row>
        <v-col cols="12" md="9">
          <label class="input-label" for="choose-date">{{
            $i18n.locale == "nl" ? "Dag(en) selecteren" : "Select day(s)"
          }}</label>
          <k-spacing :y="!is_mobile ? '4' : '1'"></k-spacing>
          <v-date-picker
            hide-header
            ref="picker"
            locale="nl"
            class="date-picker-card"
            v-model="tempSelectedDates"
            :multiple="multiple"
            color="var(--company-primary)"
            :allowed-dates="customAllowedDates"
            @update:modelValue="handleDateSelect"
          />
        </v-col>
      </v-row>

      <k-spacing y="4" />
      <label class="input-label" for="choose-date">{{
        $t("components.changeActivityDialogDialog.dialog_selected_date_l")
      }}</label>
      <v-chip-group
        variant="flat"
        selected-class="v-chip--selected"
        column
        v-model="activeDate"
      >
        <div
          class="mt-2"
          v-for="(date, dindex) in localRegisterActivity.dates"
          :key="dindex"
        >
          <v-chip
            class="time-chip"
            color="var(--company-primary)"
            :value="date.date"
            label
          >
            {{ capitalizeFirstLetter(formatDay(date.date)) }}

            <v-icon size="16" class="pa-4" @click="spliceDate(date.date)"
              >mdi-close</v-icon
            >
          </v-chip>
        </div>
      </v-chip-group>
      <div
        v-if="
          dateIndex != 'no_dateindex' && localRegisterActivity.dates[dateIndex]
        "
      >
        <k-spacing y="4"></k-spacing>
        <label class="input-label" for="choose-date">{{
          $i18n.locale == "nl" ? "Tijdslot selecteren" : "Select time slot"
        }}</label>
        <div
          class="mt-2"
          v-for="(slots, specialistName) in filterSlots(
            localRegisterActivity.dates[dateIndex].date
          )"
          :key="specialistName"
        >
          <h6>{{ specialistName }}</h6>
          <v-chip-group
            v-model="localRegisterActivity.dates[dateIndex].slot"
            variant="flat"
            selected-class="v-chip--selected"
            column
          >
            <div v-for="(item, cindex) in slots" :key="cindex">
              <v-chip
                class="time-chip"
                :class="
                  cindex !=
                  (0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60)
                    ? 'ml-0'
                    : ''
                "
                color="var(--company-primary)"
                :value="item"
                label
                v-if="item.type !== 'break' && !item.full"
              >
                {{ item.start }} - {{ item.end }}
              </v-chip>
            </div>
          </v-chip-group>
        </div>
      </div>
    </div>
    <loader
      :circle_size="180"
      v-else
      :image="false"
      height="40"
      width="170"
      class="mt-16"
    />

    <div class="step-footer pa-6 pa-md-8 pt-md-10">
      <v-row>
        <v-col cols="12" :class="!is_mobile ? 'd-flex' : ''">
          <v-btn
            v-if="is_mobile"
            :block="is_mobile"
            class="btn text-white quickfix-btn"
            color="var(--company-primary)"
            :disabled="checkDisabled()"
            @click="$emit('next-step')"
          >
            {{ $i18n.locale == "nl" ? "Ga verder" : "Continue" }}
          </v-btn>
          <k-spacing y="4" v-if="is_mobile"></k-spacing>

          <v-btn
            :block="is_mobile"
            class="btn btn-company-primary quickfix-btn"
            color="var(--company-primary)"
            variant="outlined"
            @click="$emit('prev-step')"
          >
            {{ $t("components.changeActivityDialogDialog.dialog_prev_btn") }}
          </v-btn>
          <k-spacing x="4"></k-spacing>
          <k-spacing y="4" v-if="is_mobile"></k-spacing>

          <v-btn
            v-if="!is_mobile"
            class="btn text-white quickfix-btn"
            color="var(--company-primary)"
            :disabled="checkDisabled()"
            @click="$emit('next-step')"
          >
            {{ $i18n.locale == "nl" ? "Ga verder" : "Continue" }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import config from "../../config";
import { useMainStore } from "@/stores/store";
import loader from "../loader.vue";

export default {
  components: { loader },
  props: [
    "activity",
    "locale",
    "registeractivity",
    "locationIndex",
    "is_mobile",
    "stepLabel",
    "multiple",
    "changing",
    "enroll",
  ],
  data() {
    return {
      mainstore: useMainStore(),
      activeDate: null,
      config: config,
      tempSelectedDates: this.multiple
        ? [...(this.registeractivity.dates || [])]
        : null,
      selectedDates: [],
      scrollable: false,
      credits: [],
      first_select: true,
      ready: false,
      period: null,
      localRegisterActivity: JSON.parse(JSON.stringify(this.registeractivity)), // Copy for local updates
    };
  },
  computed: {
    dateIndex() {
      return this.activeDate != null
        ? this.localRegisterActivity.dates.findIndex(
            (d) => d.date === this.activeDate
          )
        : "no_dateindex";
    },
  },
  watch: {
    tempSelectedDates() {
      this.tempSelectedDates.forEach((date) => {
        if (!this.localRegisterActivity.dates.some((d) => d.date === date)) {
          this.localRegisterActivity.dates.push({ date, slot: null });
        }
      });

      this.localRegisterActivity.dates =
        this.localRegisterActivity.dates.filter((d) =>
          this.tempSelectedDates.includes(d.date)
        );

      if (this.localRegisterActivity.dates.length === 0) {
        this.findFirstAllowedDate();
      }

      if (this.first_select && this.localRegisterActivity.dates.length === 2) {
        this.first_select = false;
        this.tempSelectedDates.splice(0, 1);
        this.selectedDates.splice(0, 1);
        this.localRegisterActivity.dates.splice(0, 1);
      }

      if (this.tempSelectedDates.length > 0) {
        const currentIndex = this.tempSelectedDates.indexOf(this.activeDate);
        this.activeDate =
          currentIndex !== -1
            ? this.tempSelectedDates[
                currentIndex < this.tempSelectedDates.length - 1
                  ? currentIndex + 1
                  : currentIndex > 0
                  ? currentIndex - 1
                  : 0
              ]
            : this.tempSelectedDates[0];
      }
    },
    localRegisterActivity: {
      deep: true,
      handler(newValue) {
        this.$emit("update-data", newValue); // Sync local data with parent
      },
    },
  },
  mounted() {
    this.checkCredits();
  },

  methods: {
    scroll() {
      const form = this.$refs[`step${this.step}Form`];

      // Scroll down the active form
      if (form) {
        const el = form.$el || form;
        el.scrollBy({
          top: 100, // Adjust as needed
          behavior: "smooth",
        });
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getCookie(name) {
      const match = document.cookie.match(
        new RegExp("(^| )" + name + "=([^;]+)")
      );
      return match ? match[2] : null;
    },
    getPeriodStartDate(date, period) {
      const dt = new Date(date);
      if (period === "week") {
        if (dt.getDay() === 0) {
          // If it's Sunday
          dt.setDate(dt.getDate() - 6); // Move back to the previous Monday
        } else {
          // For Monday to Saturday
          dt.setDate(dt.getDate() - (dt.getDay() - 1)); // Adjust to the current week's Monday
        }
      } else if (period === "month") {
        dt.setDate(1); // Set to the first day of the month
      } else if (period === "year") {
        dt.setMonth(0, 1); // Set to the first day of the year
      }
      // Manual formatting to ensure local date:
      return (
        dt.getFullYear() +
        "-" +
        ("0" + (dt.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + dt.getDate()).slice(-2)
      );
    },

    filterSlots(date) {
      if (!date) return {}; // Return empty object if no date is provided

      // Format the input date to YYYY-MM-DD
      const formattedInputDate = new Date(date)
        .toLocaleDateString("en-CA", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$1-$2"); // MM/DD/YYYY → YYYY-MM-DD

      const groupedSlots = {};
      const selectedSpecialistId = this.registeractivity.specialist;

      // Loop through all specialists in the selected location
      for (const specialist of this.activity.locations[this.locationIndex]
        ?.specialists || []) {
        const { id, name, dates } = specialist;

        // If a specific specialist is selected, skip others
        if (
          selectedSpecialistId !== "geen_voorkeur" &&
          id !== selectedSpecialistId
        ) {
          continue;
        }

        // Loop through each date of the specialist
        for (const { date: specialistDate, slots } of dates) {
          const formattedSpecialistDate = new Date(specialistDate)
            .toLocaleDateString("en-CA", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$1-$2");

          // If the specialist has available slots for the selected date
          if (formattedSpecialistDate === formattedInputDate) {
            if (!groupedSlots[name]) {
              groupedSlots[name] = [];
            }

            // Add unique slots to the specialist
            slots.forEach((slot) => {
              if (!groupedSlots[name].some((s) => s.start === slot.start)) {
                groupedSlots[name].push(slot);
              }
            });
          }
        }
      }

      return groupedSlots; // Return grouped slots per specialist
    },

    getMaxCreditsForPeriod() {
      const maxCredits = this.mainstore.companypage.settings.credits;
      const period = this.mainstore.companypage.settings.period; // 'week', 'month', or 'year'
      const today = new Date();
      let periodCredits = {};

      let currentDate = new Date(
        Date.UTC(today.getFullYear(), today.getMonth(), 1) // Ensure start of the month
      );

      // Generate max credits for the next 12 months
      for (let i = 0; i < 12; i++) {
        let startDate;

        switch (period) {
          case "week":
            startDate = new Date(currentDate);
            currentDate.setDate(currentDate.getDate() + 7); // Move forward by a week
            break;

          case "month":
            startDate = new Date(
              currentDate.getUTCFullYear(),
              currentDate.getUTCMonth(),
              1
            ); // First day of the month
            currentDate.setUTCMonth(currentDate.getUTCMonth() + 1);
            break;

          case "year":
            startDate = new Date(currentDate.getUTCFullYear(), 0, 1); // First day of the year
            currentDate.setUTCFullYear(currentDate.getUTCFullYear() + 1);
            break;

          default:
            startDate = new Date(today);
        }

        // Ensure it's in Amsterdam timezone
        let formattedDate = new Intl.DateTimeFormat("en-CA", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          timeZone: "Europe/Amsterdam", // Ensure Amsterdam time is used
        }).format(startDate);

        periodCredits[formattedDate] = maxCredits;
      }

      return periodCredits;
    },

    checkDisabled() {
      var check = [];
      if (this.registeractivity.dates.length == 0) {
        return true;
      } else {
        this.registeractivity.dates.forEach((element) => {
          if (element.slot == null) {
            check.push(false);
          }
        });
        if (check.includes(false)) {
          return true;
        } else {
          return false;
        }
      }
    },

    checkCredits() {
      const token = this.getCookie("fmw_access_token");

      if (token) {
        api
          .post(
            `${config.url.api}/v1/activities/${this.activity.page}/checkcredits`,
            {
              dates: this.getCustomAllowedDates(),
            }
          )
          .then((res) => {
            this.credits = res.data.dates;
            this.period = res.data.period;
            if (this.changing && this.enroll.date) {
              const formattedEnrollDate = this.formatDateToPeriod(
                new Date(this.enroll.date * 1000),
                this.period
              );

              if (this.credits[formattedEnrollDate]) {
                this.credits[formattedEnrollDate] += 2;
              } else {
                this.credits[formattedEnrollDate] = 2;
              }
            }
            this.findFirstAllowedDate();
            this.ready = true;
          });
      } else {
        this.credits = this.getMaxCreditsForPeriod();
        this.period = this.mainstore.companypage.settings.period;
        this.findFirstAllowedDate();
        this.ready = true;
      }
    },
    formatDateToPeriod(date, period) {
      // Ensure date is in Amsterdam time
      const dt = new Date(
        date.toLocaleString("en-US", { timeZone: "Europe/Amsterdam" })
      );

      if (period === "week") {
        const dayOfWeek = dt.getDay() || 7; // Convert Sunday (0) to 7
        dt.setDate(dt.getDate() - dayOfWeek + 1); // Move to Monday
      } else if (period === "month") {
        dt.setDate(1); // First day of the month
      } else if (period === "year") {
        dt.setMonth(0, 1); // January 1st
      }

      // Format in YYYY-MM-DD (Europe/Amsterdam timezone)
      return new Intl.DateTimeFormat("en-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        timeZone: "Europe/Amsterdam",
      }).format(dt);
    },
    getCustomAllowedDates() {
      const allowedDates = [];

      for (const { id, dates } of this.activity.locations[this.locationIndex]
        .specialists) {
        for (const { date: specialistDate, slots } of dates) {
          const formattedDate = new Date(specialistDate)
            .toISOString()
            .split("T")[0];

          if (
            this.registeractivity.specialist === "geen_voorkeur" &&
            slots.length > 0
          ) {
            allowedDates.push(formattedDate);
          }
          if (this.registeractivity.specialist === id && slots.length > 0) {
            allowedDates.push(formattedDate);
          }
        }
      }

      return [...new Set(allowedDates)];
    },

    findFirstAllowedDate() {
      let date = new Date();
      date.setHours(0, 0, 0, 0);

      for (let i = 0; i < 365; i++) {
        if (this.customAllowedDates(date)) {
          this.tempSelectedDates = [date];
          this.localRegisterActivity.dates = [{ date, slot: null }];
          break;
        }
        date.setDate(date.getDate() + 1);
      }
    },

    handleDateSelect(newDates) {
      if (!this.multiple) {
        newDates = [newDates];
      }

      // Ensure `newDates` contains only Date objects
      const formattedNewDates = newDates.map((date) => new Date(date));

      // Find newly added date
      const newlyAddedDate = formattedNewDates.find(
        (date) =>
          !this.selectedDates.some(
            (selectedDate) => selectedDate.getTime() === date.getTime()
          )
      );

      if (newlyAddedDate) {
        const formattedInputDate = newlyAddedDate.toISOString().split("T")[0]; // YYYY-MM-DD format
        const periodStartDate = this.getPeriodStartDate(
          formattedInputDate,
          this.period
        );

        // Group dates by period
        const datesGroupedByPeriod = this.selectedDates.reduce((acc, date) => {
          const formattedDate = date.toISOString().split("T")[0];
          const periodStart = this.getPeriodStartDate(
            formattedDate,
            this.period
          );

          if (!acc[periodStart]) acc[periodStart] = [];
          acc[periodStart].push(date);
          return acc;
        }, {});

        const datesInPeriod = datesGroupedByPeriod[periodStartDate] || [];
        console.log(this.credits[periodStartDate], "tester");

        if (datesInPeriod.length < this.credits[periodStartDate]) {
          // Allow adding new date
          this.selectedDates = formattedNewDates;
          this.tempSelectedDates = [...formattedNewDates];
        } else if (
          datesInPeriod.length === 1 &&
          this.credits[periodStartDate] === 1
        ) {
          // 🔥 Instead of blocking, REPLACE the previously selected date with the new one
          this.selectedDates = [newlyAddedDate];
          this.tempSelectedDates = [newlyAddedDate];
        } else {
          // Reject new date selection
          this.$toast.error(
            this.$i18n.locale === "nl"
              ? "Sorry! Je credits voor deze periode zijn helaas op."
              : "Sorry! You don't have any more credits for this period."
          );
          // Revert selection to last valid state
          this.tempSelectedDates = [...this.selectedDates];
        }
      } else {
        // If no new date was added, update normally (handling date removals)
        this.selectedDates = formattedNewDates;
        this.tempSelectedDates = [...formattedNewDates];
      }

      this.syncActiveDate();
    },

    syncActiveDate() {
      this.activeDate =
        this.selectedDates.length > 0 ? this.selectedDates[0] : null;
    },

    spliceDate(date) {
      this.tempSelectedDates = this.tempSelectedDates.filter((d) => d !== date);
      this.selectedDates = this.selectedDates.filter((d) => d !== date);

      this.syncActiveDate();
    },

    formatDay(date) {
      return new Date(date).toLocaleDateString("nl-NL", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    },

    customAllowedDates(date) {
      const formattedDate = new Date(date).toISOString().split("T")[0];

      return this.activity.locations[this.locationIndex].specialists.some(
        ({ id, dates }) =>
          dates.some(({ date: specialistDate, slots }) => {
            const formattedSpecialistDate = new Date(specialistDate)
              .toISOString()
              .split("T")[0];

            return (
              (formattedSpecialistDate === formattedDate &&
                this.registeractivity.specialist === id &&
                slots.length > 0) ||
              (formattedSpecialistDate === formattedDate &&
                this.registeractivity.specialist == "geen_voorkeur" &&
                slots.length > 0)
            );
          })
      );
    },

    emitUpdateAndNextStep() {
      this.$emit("update-data", this.localRegisterActivity);
      this.$emit("next-step");
    },
  },
};
</script>
