<template>
  <v-dialog v-model="delete_dialog" :width="width" content-class="card ">
    <v-btn
      icon
      dark
      variant="text"
      class="dialog-close-btn"
      :class="is_mobile ? 'mt-n4 mr-n4' : ''"
      @click="$emit('close-dialog')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <div :class="is_mobile ? 'overflow-y-scroll overflow-x-hidden' : ''">
      <div class="text-center" :class="is_mobile ? 'mt-6 mb-6' : ''">
        <h2 class="mb-4">
          {{
            !employer
              ? $t("components.unsubscribeConfirmDialog.text")
              : $i18n.locale == "nl"
              ? `Weet je zeker dat je ${user} wilt afmelden voor de `
              : `Are you sure you want to unsubscribe ${user} for the `
          }}{{ title }}?
        </h2>
        <span class="description-text">
          {{
            !employer
              ? $i18n.locale == "nl"
                ? `Voor afmeldingen of wijzigingen vragen wij u vriendelijk om dit minimaal ${mainstore.companypage.settings.cancellation_period} uur van tevoren te doen.`
                : `For cancellations or changes, we kindly ask you to do so at least ${mainstore.companypage.settings.cancellation_period} hours in advance.`
              : $i18n.locale == "nl"
              ? `Voor afmeldingen of wijzigingen is de opzegperiode ${mainstore.companypage.settings.cancellation_period} uur van tevoren.`
              : `For cancellations or changes, the cancellationperiod is ${mainstore.companypage.settings.cancellation_period} hours in advance.`
          }}
        </span>
      </div>

      <k-spacing :y="is_mobile ? 6 : 4"></k-spacing>
      <span class="unsubscribe-text">
        {{ $i18n.locale == "nl" ? "Afmeld gegevens" : "Unsubscribe details" }}
      </span>
      <v-row class="unsubscribe-row pa-2 mt-4 mb-4">
        <v-col cols="6" md="2" class="py-1">
          <span>
            {{
              $t(
                "components.registerActivityDialog.dialog_stepper_title_4_l_summary_4"
              )
            }}
          </span>
        </v-col>
        <v-col cols="6" md="10" class="py-1">
          <span class="text-bold">
            {{
              new Date(enroll.date * 1000)
                .toLocaleDateString("nl-NL", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })
                .replace(/(\w{3})/, "$1.")
            }}
          </span>
        </v-col>

        <v-col cols="6" md="2" class="">
          <span>
            {{
              $t(
                "components.registerActivityDialog.dialog_stepper_title_4_l_summary_5"
              )
            }}
          </span>
        </v-col>
        <v-col cols="6" md="10" class="">
          <span class="text-bold">
            {{ enroll.start }} -
            {{ enroll.end }}
          </span>
        </v-col>
      </v-row>
      <div
        :class="!is_mobile ? 'pa-4 pb-0 mt-4 d-flex justify-center' : 'mt-4'"
      >
        <v-btn
          @click="deleteitem()"
          variant="flat"
          :block="is_mobile"
          class="btn text-white"
          v-if="is_mobile"
          :class="is_mobile ? 'mt-4' : ''"
          color="var(--company-primary)"
        >
          {{
            !employer
              ? $t("components.unsubscribeConfirmDialog.delete_btn_remove")
              : $i18n.locale == "nl"
              ? "Afmelden"
              : "Unsubscribe"
          }}
        </v-btn>
        <v-btn
          @click="$emit('close-dialog')"
          class="btn btn-tertiary mr-4"
          :block="is_mobile"
          :class="is_mobile ? 'mt-4' : ''"
          color="var(--company-primary)"
          variant="outlined"
        >
          {{ $t("components.unsubscribeConfirmDialog.delete_btn_cancel") }}
        </v-btn>
        <v-btn
          @click="deleteitem()"
          variant="flat"
          :block="is_mobile"
          class="btn text-white"
          v-if="!is_mobile"
          color="var(--company-primary)"
        >
          {{
            !employer
              ? $t("components.unsubscribeConfirmDialog.delete_btn_remove")
              : $i18n.locale == "nl"
              ? "Afmelden"
              : "Unsubscribe"
          }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { useMainStore } from "@/stores/store";

export default {
  components: {},
  computed: {
    is_mobile() {
      return window.innerWidth <= 768;
    },
    width() {
      return !window.innerWidth <= 768 ? "640px" : "calc(100% - 32px)";
    },
  },
  props: {
    title: String,
    enroll: Object,
    employer: Boolean,
    user: String,
  },
  data: () => ({
    delete_dialog: false,
    mainstore: useMainStore(),
  }),

  methods: {
    deleteitem() {
      this.$emit("delete");
    },
  },
};
</script>
