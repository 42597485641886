<template>
  <v-dialog
    v-model="relocation_dialog"
    persistent
    :width="is_mobile ? 500 : 640"
    content-class="card overflow-hidden confirm-dialog"
  >
    <v-btn
      icon
      dark
      variant="text"
      class="dialog-close-btn"
      @click="$emit('close-dialog')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <div>
      <div class="banner-confirm-thnx-img">
        <img :src="`${configdata.url.cdn}/activities/${image}`" />
      </div>
      <k-spacing :y="is_mobile ? 12 : 8"></k-spacing>
      <h3 class="step-title mt-md-16 mt-sm-8">
        {{
          $i18n.locale == "nl"
            ? "Wijziging is gelukt!"
            : "Change is successful!"
        }}
      </h3>
      <k-spacing y="4"></k-spacing>

      <p
        v-html="
          $i18n.locale == 'nl'
            ? `Jouw aanmelding is succesvol verplaatst  naar  <strong>${new Date(
                new_date * 1000
              )
                .toLocaleDateString('nl-NL', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })
                .replace(
                  /(\w{3})/,
                  '$1.'
                )}</strong>, <strong>${new_start} - ${new_end}</strong>`
            : ''
        "
      ></p>

      <k-spacing y="8"></k-spacing>

      <v-row>
        <v-col cols="12" class="d-flex">
          <v-btn
            class="btn quickfix-btn text-white"
            :block="is_mobile"
            color="var(--company-primary)"
            @click="$emit('close-dialog')"
          >
            {{ $i18n.locale == "nl" ? "Afsluiten" : "Close" }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-dialog>
</template>

<script>
import { useMainStore } from "../stores/store";
import config from "@/config";
export default {
  components: {},
  props: {
    title: String,
    image: String,
    new_start: String,
    new_end: String,
    new_date: String,
    old_start: String,
    old_end: String,
    old_date: String,
  },
  data: () => ({
    config: config,
    mainstore: useMainStore(),
  }),
  watch: {
    relocation_dialog() {
      this.$emit("close-dialog");
    },
  },
  computed: {
    configdata() {
      return config;
    },
    is_mobile() {
      return window.innerWidth <= 768;
    },
  },

  methods: {},
};
</script>
